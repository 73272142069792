.p-breadcrumb
	border: 0
	font-weight: normal
	padding: 0
	&>ul li:first-child
		margin-left: 0
	&>ul li:last-child a
		user-select: none
		cursor: text
.p-menuitem-text
	font-size: 0.85rem
.p-breadcrumb-chevron
	margin: 0 0.4rem
	&::before
		content: '/'
		color: $clLightSilverChalice