@import "ThemeSet/components/input.sass" 

.p-inputtext
	&.ng-touched.ng-invalid, &.ng-dirty.ng-invalid
		border: 1px solid $clCrimson
		& + .ui-input-prefix
			border: 1px solid $clCrimson
			border-right: none
		&:enabled:hover, &:focus
			border: 1px solid $clCrimson
	&:enabled:hover, &:enabled:focus
		border: 1px solid #a8a8a8 !important
		box-shadow: none
	&::-webkit-input-placeholder
		color: $clAlto
	&::-moz-placeholder
		color: $clAlto
	&:-ms-input-placeholder
		color: $clAlto
	&:-moz-placeholder
		color: $clAlto