@import 'breakpoints.sass' 
.ui-dialog-mask
	opacity: 0.8
.ui-dialog
	box-shadow: none
	border: 0
	border-top: 1px solid $clFlushOrange
	min-width: 500px
	display: flex !important
	flex-direction: column
	background: $clAlabaster
	padding: 1.5rem
	+for-size(tablet-down)
		min-width: 100vw
		width: 100vw !important
		min-height: 100vh
		max-height: 100vh
		top: 0 !important
		left: 0 !important
		bottom: 0 !important
	.ui-dialog-titlebar
		background: $clAlabaster
		padding: 0
	.ui-dialog-content
		background: $clAlabaster
		display: flex
		justify-content: flex-start
		flex-direction: column
		padding: 1rem 0
		overflow-y: visible !important
		height: 100% !important
		@media screen and (max-width: 768px)
			height: 90% !important
		&:active
			outline: unset !important
	&-confirmation
		display: flex
		flex-direction: column
		align-items: center
		&-icon
			color: $clFlushOrange
		&-message
			color: $clTundora
			text-align: center
			font-size: 1rem
			margin-bottom: 0
	&-close-icon
		position: absolute
		cursor: pointer
		top: -.25rem
		right: 0
.p-dialog
	max-height: 100% !important
	box-shadow: none
	border: 0
	border-top: 1px solid $clFlushOrange
	min-width: 500px
	display: flex !important
	flex-direction: column
	background: $clAlabaster
	padding: 1.5rem
	&-title
		font-family: $fontFamily
		color: $clTundora
		font-size: 1rem 
		font-weight: 500
	&-content
		background: $clAlabaster
		display: flex
		justify-content: flex-start
		flex-direction: column
		padding: 1rem 0
		overflow-y: visible !important
		height: 100% !important
		@media screen and (max-width: 768px)
			height: 90% !important
		&:active
			outline: unset !important
	&-header
		position: relative
	&-footer
		border: 0
		background: $clAlabaster
		padding: .25rem 0 0
		text-align: right
	+for-size(tablet-down)
		min-width: 100vw
		width: 100vw !important
		min-height: 100vh
		max-height: 100vh
		top: 0 !important
		left: 0 !important
		bottom: 0 !important