/*
* Deprecated: Use nova instead
*/
@mixin hover-element() {
    border-color: $stateHoverBorderColor;
    background-color: $stateHoverBgColor;
    color: $stateHoverTextColor;

    a {
        color: $stateHoverTextColor;
    }
}

@mixin icon-override($icon) {
    background: none !important;
    font-family: 'primeicons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-indent: 0px !important;
    text-align: center;

    &:before {
        content: $icon;
    }
}

.p-component {
    font-family:  $fontFamily;
    font-size: $fontSize;

    input, select, textarea, button {
        font-family:  $fontFamily;
        font-size: $fontSize;
    }

    :active {
        outline: none;
    }
}

.ui-widget-content {
    border: $contentBorderWidth solid $contentBorderColor;
    background-color: $contentBgColor;
    color: $contentTextColor;

    a {
        color: $contentTextColor;
    }
}

.ui-widget-header {
    border: $headerBorderWidth solid $headerBorderColor;
    background-color: $headerBgColor;
    color: $headerTextColor;
    font-weight: $headerFontWeight;

    a {
        color: $headerTextColor;
    }
}

.ui-widget-overlay {
    background-color: #666666;
    opacity: .50;
    filter:Alpha(Opacity=50);
}

.ui-state-default {
    border: $stateDefaultBorderWidth solid $stateDefaultBorderColor;
    background-color: $stateDefaultBgColor;
    color: $stateDefaultTextColor;

    a {
        color: $stateDefaultTextColor ;
    }
}

.ui-state-active {
    border-color: $stateActiveBorderColor;
    background-color: $stateActiveBgColor;
    color: $stateActiveTextColor;

    a {
        color: $stateActiveTextColor;
    }
}

.ui-state-highlight  {
    border-color: $stateHighlightBorderColor;
    background-color: $stateHighlightBgColor;
    color: $stateHighlightTextColor;

    a {
        color: $stateHighlightTextColor;
    }
}

.ui-state-focus {
    border-color: $stateFocusBorderColor;
    background-color: $stateFocusBgColor;
    color: $stateFocusTextColor;

    a {
        color: $stateFocusTextColor;
    }
}

.ui-state-error {
    border-color: $stateErrorBorderColor;
    background-color: $stateErrorBgColor;
    color: $stateErrorTextColor;

    a {
        color: $stateErrorTextColor;
    }
}

.ui-state-disabled,
.ui-widget:disabled {
    opacity: $disabledOpacity;
    filter: Alpha(Opacity= $disabledOpacity * 100);
    background-image: none;
    cursor: default !important;

    * {
        cursor: default !important;
    }
}

/* Forms */
.ui-inputtext {
    background-color: $inputBgColor;
    color: $inputTextColor;
}

.ui-inputtext:enabled:hover {
    border-color: $stateHoverBorderColor;
}

.ui-inputtext.ui-state-focus,
.ui-inputtext:focus {
    outline: 0 none;
    border-color: $stateFocusBorderColor;
    -moz-box-shadow: 0px 0px 5px $stateFocusBorderColor;
    -webkit-box-shadow: 0px 0px 5px $stateFocusBorderColor;
    box-shadow: 0px 0px 5px $stateFocusBorderColor;
}

.ui-inputgroup {
    .ui-inputgroup-addon  {
        border-color: $stateDefaultBorderColor;
        background-color: lighten($stateDefaultBorderColor, 10%);
        color: $inputGroupTextColor;

        &:first-child {
            border-top-left-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;
        }

        &:last-child {
            border-top-right-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;
        }
    }

    .ui-button {
        &:first-child {
            border-top-left-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;
        }

        &:last-child {
            border-top-right-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;
        }
    }
}

.ui-float-label input.ng-dirty.ng-invalid ~ label {
    color: $stateErrorTextColor;
}

.ui-autocomplete {
    .ui-autocomplete-multiple-container:not(.ui-state-disabled) {
        &:hover {
            border-color: $stateHoverBorderColor;
        }

        &.ui-state-focus {
            border-color: $stateFocusBorderColor;

        }
    }
}

.ui-chips {
    > ul:not(.ui-state-disabled) {
        &:hover {
            border-color: $stateHoverBorderColor;
        }

        &.ui-state-focus {
            border-color: $stateFocusBorderColor;
        }
    }
}

.ui-button:focus,
.ui-button:enabled:hover,
.ui-fileupload-choose:not(.ui-state-disabled):hover {
    outline: 0 none;
    @include hover-element();
}

.ui-button:enabled:active,
.ui-fileupload-choose:not(.ui-state-disabled):active {
    border-color: $stateActiveBorderColor;
    background-color: $stateActiveBgColor;
    color: $stateActiveTextColor;
}

.ui-chkbox-box:not(.ui-state-disabled):not(.ui-state-active):hover {
    @include hover-element();
}

.ui-radiobutton-box:not(.ui-state-disabled):not(.ui-state-active):hover {
    @include hover-element();
}

.ui-dropdown .ui-dropdown-clear-icon {
    color: lighten($inputTextColor, 40%);
}

.ui-dropdown:not(.ui-state-disabled):hover {
    @include hover-element();
}

.ui-dropdown-panel .ui-dropdown-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
    @include hover-element();
}

.ui-listbox {
    .ui-listbox-header {
        .ui-listbox-filter-container {
            .fa {
                color: $inputTextColor;
            }
        }
    }

    &:not(.ui-state-disabled) {
        .ui-listbox-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
            @include hover-element();
        }

        .ui-listbox-item.ui-state-disabled .ui-chkbox-box:hover {
            background-color: $inputBgColor;
            border-color: $stateDefaultBorderColor;
        }
    }

    &.ui-state-disabled {
        .ui-chkbox-box:not(.ui-state-active):hover {
            border-color: $stateDefaultBorderColor;
            background-color: $stateDefaultBgColor;
            color: $stateDefaultTextColor;
        }
    }
}

.ui-multiselect:not(.ui-state-disabled):hover {
    @include hover-element();
}

.ui-multiselect-panel .ui-multiselect-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
    @include hover-element();
}

.ui-multiselect-panel .ui-multiselect-item.ui-state-disabled:hover .ui-chkbox-box {
    background-color: $inputBgColor;
    border-color: $stateDefaultBorderColor;
}

.ui-multiselect-panel .ui-multiselect-close {
    color: $headerIconTextColor;
}

.ui-multiselect-panel .ui-multiselect-filter-container .fa {
    color: $inputTextColor;
}

.ui-spinner:not(.ui-state-disabled) .ui-spinner-button:enabled:hover {
    @include hover-element();
}

.ui-spinner:not(.ui-state-disabled) .ui-spinner-button:enabled:active {
    border-color: $stateActiveBorderColor;
    background-color: $stateActiveBgColor;
    color: $stateActiveTextColor;
}

.ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover {
    @include hover-element();
}

.ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active):hover {
    @include hover-element();
}

.ui-paginator a:not(.ui-state-disabled):not(.ui-state-active):hover {
    @include hover-element();
}

.ui-paginator a {
    color: $stateDefaultTextColor;
}

.p-datatable {
    .ui-rowgroup-header a {
        color: $headerTextColor;
    }

    .ui-sortable-column:not(.ui-state-active):hover {
        background-color: $stateHoverBgColor;
        color: $stateHoverTextColor;
    }

    .ui-row-toggler {
        color: $contentTextColor;
    }

    tbody.ui-datatable-hoverable-rows {
        > tr.ui-widget-content:not(.ui-state-highlight):hover {
            cursor: pointer;
            background-color: $stateHoverBgColor;
            color: $stateHoverTextColor;
        }
    }
}

.ui-orderlist {
    .ui-orderlist-item:not(.ui-state-highlight):hover {
        @include hover-element();
    }
}

.ui-picklist {
    .ui-picklist-item:not(.ui-state-disabled):not(.ui-state-highlight):hover {
        @include hover-element();
    }

    .ui-picklist-droppoint-highlight {
        border-color: $stateHighlightBorderColor;
        background-color: $stateHighlightBgColor;
        color: darken($contentTextColor,1%);

        a {
            color: darken($contentTextColor,1%);
        }
    }

    .ui-picklist-highlight {
        border-color: $stateHighlightBorderColor;
        color: darken($contentTextColor,1%);

        a {
            color: darken($contentTextColor,1%);
        }
    }
}

.ui-tree {
    &.ui-treenode-dragover {
        border-color: $stateHighlightBorderColor;
    }

    .ui-treenode-content {
        &.ui-treenode-selectable {
            .ui-treenode-label:not(.ui-state-highlight):hover {
                @include hover-element();
            }
        }

        &.ui-treenode-dragover {
            background-color: $stateActiveBgColor;
            color: $stateActiveTextColor;
        }
    }

    &.ui-tree-horizontal {
        .ui-treenode-content.ui-treenode-selectable {
            .ui-treenode-label:not(.ui-state-highlight):hover {
                background-color: inherit;
                color: inherit;
            }

            &:not(.ui-state-highlight):hover {
                @include hover-element();
            }
        }
    }
}

.ui-organizationchart {
    .ui-organizationchart-node-content {
        &.ui-organizationchart-selectable-node:not(.ui-state-highlight):hover {
            @include hover-element();
        }
    }
}

.ui-accordion {
    .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover {
        @include hover-element();
    }
}

.ui-fieldset {
    &.ui-fieldset-toggleable {
        .ui-fieldset-legend:hover {
            @include hover-element();
        }
    }
}

.ui-panel {
    .ui-panel-titlebar {
        .ui-panel-titlebar-icon:hover {
            @include hover-element();
        }
    }
}

.ui-tabview {
    .ui-tabview-nav {
        li {
            &:not(.ui-state-active):not(.ui-state-disabled):hover {
                @include hover-element();
            }
        }
    }
}

.ui-dialog {
    .ui-dialog-titlebar-icon {
        color: $headerTextColor;

        &:hover {
            @include hover-element();
        }
    }
}

.ui-sidebar {
    .ui-sidebar-close {
        color: $headerTextColor;

        &:hover {
            @include hover-element();
        }
    }
}

.ui-overlaypanel {
    .ui-overlaypanel-close:hover {
        @include hover-element();
    }
}

.ui-inplace {
    .ui-inplace-display:hover {
        @include hover-element();
    }
}

.p-breadcrumb {
    a {
        color: $headerTextColor;
    }
}

.ui-menuitem {
    .ui-menuitem-link {
        color: $contentTextColor;

        &:hover {
            @include hover-element();
            border-color: transparent;
        }
    }

    &.ui-menuitem-active {
        > .ui-menuitem-link {
            @include hover-element();
            border-color: transparent;
        }
    }
}

.ui-tabmenu {
    .ui-tabmenu-nav {
        li:not(.ui-state-active):hover {
            @include hover-element();
        }
    }
}

.ui-steps {
    .ui-steps-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
        @include hover-element();
    }
}

.ui-panelmenu {
    .ui-panelmenu-header {
        &:not(.ui-state-active):hover {
            @include hover-element();
            border-color: $stateDefaultBorderColor;

            a {
                color: $stateHoverTextColor;
            }
        }

        &.ui-state-active {
            a {
                color: $stateActiveTextColor;
            }
        }
    }

    .ui-panelmenu-content {
        .ui-menuitem-link {
            color: $contentTextColor;

            &:hover {
                @include hover-element();
                border-color: transparent;
            }
        }
    }
}

.ui-datepicker {
    .ui-datepicker-header {
        a {
            color: $headerTextColor;

            &:hover {
                @include hover-element();
            }
        }
    }

    .ui-datepicker-calendar {
        td:not(.ui-state-disabled) {
            a:hover {
                @include hover-element();
            }
        }
    }

    .ui-monthpicker {
        .ui-monthpicker-month:hover {
            @include hover-element();
        }
    }
}

.fc {
    .fc-toolbar {
        .fc-prev-button {
            .ui-icon-circle-triangle-w {
                margin-top: .3em;
                @include icon_override("\e900");
            }
        }

        .fc-next-button {
            .ui-icon-circle-triangle-e {
                margin-top: .3em;
                @include icon_override("\e901");
            }
        }
    }
}

.ui-rating {
    a {
        color: $inputTextColor;
    }
}

.ui-organizationchart {
    .ui-organizationchart-line-down {
        background-color: darken($contentBorderColor, 10%);
    }

    .ui-organizationchart-line-left {
        border-right: 1px solid darken($contentBorderColor, 10%);
    }

    .ui-organizationchart-line-top {
        border-top: 1px solid darken($contentBorderColor, 10%);
    }

    .ui-organizationchart-node-content {
        border-color: darken($contentBorderColor, 10%);
    }

    .ui-organizationchart-node-content .ui-node-toggler {
        color: darken($contentBorderColor, 10%);
    }
}

/* TurboTable */
.ui-table {
    .ui-table-thead > tr > th,
    .ui-table-tfoot > tr > td {
        background-color: $headerBgColor;
        border: 1px solid $headerBorderColor;
        color: $headerTextColor;
    }

    .ui-table-tbody > tr {
        background-color: $contentBgColor;
        color: $contentTextColor;

        > td {
            border: 1px solid $contentBorderColor;
            background-color: inherit;
        }

        &.ui-state-highlight {
            background-color: $stateHighlightBgColor;
            color: $stateHighlightTextColor;
        }

        &.ui-contextmenu-selected {
            background-color: lighten($stateHighlightBgColor, 20%);
            color: $stateHighlightTextColor;
        }
    }

    .p-sortable-column {
        .ui-sortable-column-icon {
            color: $headerTextColor;
        }

        &.ui-state-highlight {
            background-color: $stateHighlightBgColor;
            color: $stateHighlightTextColor;

            .p-sortable-column-icon {
                color: $stateHighlightTextColor;
            }
        }

        &:not(.ui-state-highlight):hover {
            background-color: $stateHoverBgColor;
            color: $stateHoverTextColor;

            .p-sortable-column-icon {
                color: $stateHoverTextColor;
            }
        }
    }

    &.ui-table-hoverable-rows {
        .ui-table-tbody > tr:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
            cursor: pointer;
            background-color: $stateHoverBgColor;
            color: $stateHoverTextColor;
        }
    }
}

@media ( max-width: 35em ) {
    .ui-table-responsive .ui-table-tbody > tr {
        border-top: 1px solid $contentBorderColor;
        border-bottom: 1px solid $contentBorderColor;
    }
}

/* Row Reorder */
.ui-table .ui-table-tbody > tr.ui-table-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 $stateHighlightBgColor;
}

.ui-table .ui-table-tbody > tr.ui-table-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 $stateHighlightBgColor;
}

/* TurboTable */
.ui-treetable {
    .ui-treetable-thead > tr > th,
    .ui-treetable-tfoot > tr > td {
        background-color: $headerBgColor;
        border: 1px solid $headerBorderColor;
        color: $headerTextColor;
    }

    .ui-treetable-tbody > tr {
        background-color: $contentBgColor;
        color: $contentTextColor;

        > td {
            border: 1px solid $contentBorderColor;
            background-color: inherit;
        }

        &.ui-state-highlight {
            background-color: $stateHighlightBgColor;
            color: $stateHighlightTextColor;

            .ui-treetable-toggler {
                color: $stateHighlightTextColor;
            }
        }

        &.ui-contextmenu-selected {
            background-color: lighten($stateHighlightBgColor, 20%);
            color: $stateHighlightTextColor;

            .ui-treetable-toggler {
                color: $stateHighlightTextColor;
            }
        }

        .ui-treetable-toggler {
            color: $contentTextColor;
        }
    }

    .ui-sortable-column {
        .ui-sortable-column-icon {
            color: $headerTextColor;
        }

        &.ui-state-highlight {
            background-color: $stateHighlightBgColor;
            color: $stateHighlightTextColor;

            .ui-sortable-column-icon {
                color: $stateHighlightTextColor;
            }
        }

        &:not(.ui-state-highlight):hover {
            background-color: $stateHoverBgColor;
            color: $stateHoverTextColor;

            .ui-sortable-column-icon {
                color: $stateHoverTextColor;
            }
        }
    }

    &.ui-treetable-hoverable-rows {
        .ui-treetable-tbody > tr:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
            cursor: pointer;
            background-color: $stateHoverBgColor;
            color: $stateHoverTextColor;
        }
    }
}

@media ( max-width: 35em ) {
    .ui-treetable-responsive .ui-treetable-tbody > tr {
        border-top: 1px solid $contentBorderColor;
        border-bottom: 1px solid $contentBorderColor;
    }
}

/* Row Reorder */
.ui-treetable .ui-treetable-tbody > tr.ui-treetable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 $stateHighlightBgColor;
}

.ui-treetable .ui-treetable-tbody > tr.ui-treetable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 $stateHighlightBgColor;
}

/* InputSwitch */
.ui-inputswitch {
    .ui-inputswitch-slider {
        -webkit-transition: background-color .3s, box-shadow .2s;
        transition: background-color .3s, box-shadow .2s;
        background: #cccccc; 
    
        &:before {
            background-color: #ffffff;
        }
    }

    &.ui-inputswitch-focus {
        .ui-inputswitch-slider {
            -moz-box-shadow: 0px 0px 5px $stateFocusBorderColor;
            -webkit-box-shadow: 0px 0px 5px $stateFocusBorderColor;
            box-shadow: 0px 0px 5px $stateFocusBorderColor;
        } 
    }

    &:not(.ui-state-disabled):hover {
        .ui-inputswitch-slider {
            background-color: #b7b7b7;
        }
    }

    &.ui-inputswitch-checked {
        .ui-inputswitch-slider {
            background-color: #186ba0;
        }

        &:not(.ui-state-disabled):hover {
            .ui-inputswitch-slider {
                background-color: darken(#186ba0, 10%);
            }
        }
    }
}

/* Validation */
.ui-inputtext.ng-dirty.ng-invalid,
p-dropdown.ng-dirty.ng-invalid > .ui-dropdown,
p-autocomplete.ng-dirty.ng-invalid > .ui-autocomplete > .ui-inputtext,
p-calendar.ng-dirty.ng-invalid > .ui-calendar > .ui-inputtext,
p-chips.ng-dirty.ng-invalid > .ui-inputtext,
p-inputmask.ng-dirty.ng-invalid > .ui-inputtext,
p-checkbox.ng-dirty.ng-invalid .ui-chkbox-box,
p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box,
p-inputswitch.ng-dirty.ng-invalid .ui-inputswitch,
p-listbox.ng-dirty.ng-invalid .ui-inputtext,
p-multiselect.ng-dirty.ng-invalid > .ui-multiselect,
p-spinner.ng-dirty.ng-invalid > .ui-inputtext,
p-selectbutton.ng-dirty.ng-invalid .ui-button,
p-togglebutton.ng-dirty.ng-invalid .ui-button {
    border-bottom-color: $invalidInputBorderColor;
}

/* Cornering */
.ui-corner-tl { -moz-border-radius-topleft: $borderRadius; -webkit-border-top-left-radius: $borderRadius; border-top-left-radius: $borderRadius; }
.ui-corner-tr { -moz-border-radius-topright: $borderRadius; -webkit-border-top-right-radius: $borderRadius; border-top-right-radius: $borderRadius; }
.ui-corner-bl { -moz-border-radius-bottomleft: $borderRadius; -webkit-border-bottom-left-radius: $borderRadius; border-bottom-left-radius: $borderRadius; }
.ui-corner-br { -moz-border-radius-bottomright: $borderRadius; -webkit-border-bottom-right-radius: $borderRadius; border-bottom-right-radius: $borderRadius; }
.ui-corner-top { -moz-border-radius-topleft: $borderRadius; -webkit-border-top-left-radius: $borderRadius; border-top-left-radius: $borderRadius; -moz-border-radius-topright: $borderRadius; -webkit-border-top-right-radius: $borderRadius; border-top-right-radius: $borderRadius; }
.ui-corner-bottom { -moz-border-radius-bottomleft: $borderRadius; -webkit-border-bottom-left-radius: $borderRadius; border-bottom-left-radius: $borderRadius; -moz-border-radius-bottomright: $borderRadius; -webkit-border-bottom-right-radius: $borderRadius; border-bottom-right-radius: $borderRadius; }
.ui-corner-right {  -moz-border-radius-topright: $borderRadius; -webkit-border-top-right-radius: $borderRadius; border-top-right-radius: $borderRadius; -moz-border-radius-bottomright: $borderRadius; -webkit-border-bottom-right-radius: $borderRadius; border-bottom-right-radius: $borderRadius; }
.ui-corner-left { -moz-border-radius-topleft: $borderRadius; -webkit-border-top-left-radius: $borderRadius; border-top-left-radius: $borderRadius; -moz-border-radius-bottomleft: $borderRadius; -webkit-border-bottom-left-radius: $borderRadius; border-bottom-left-radius: $borderRadius; }
.ui-corner-all { -moz-border-radius: $borderRadius; -webkit-border-radius: $borderRadius; border-radius: $borderRadius; }
