.ui-paginator
	display: flex
	justify-content: flex-end
	align-content: flex-end
	padding-right: 20px
	padding-top: 10px
	border: none
	.ui-paginator-first
		margin: 13px 5px
	.ui-paginator-prev
		margin: 13px 5px
	.ui-paginator-pages
		margin: 6px 5px
	.ui-paginator-page
		margin: 6px 5px
	.ui-paginator-next
		margin: 13px 5px
	.ui-paginator-last
		margin: 13px 5px