.ui-block
	background: $clWhite
	padding: 1.5rem
	margin-bottom: 1rem
	border: 1px solid $clGallery
	border-top: 1px solid $clFlushOrange
	&>.ui-block-header
		margin: -0.5rem 0 1rem 0

	@media screen and (max-width: 640px)
		padding: .75rem