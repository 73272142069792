.ui-accordion
	&-header
		border: none
		outline: none
		background-color: transparent
		&.ui-state-active
			border: none
			background-color: transparent
		a
			outline: none
	& &-header:not(.ui-state-active):not(.ui-state-disabled):hover
		border: none
		background-color: transparent
	& &-content
		padding: .5rem
		background-color: transparent
		border: none