.p-fileupload
	position: relative
	&-files
		padding-top: 60px
		width: 75%
.p-fileupload-buttonbar
	height: 0
	padding: 0
	border: none
	.p-fileupload-choose
		position: absolute
		top: 1rem
		left: 50%
		transform: translateX(-50%)
		z-index: 2
		border-radius: 50px
		box-sizing: border-box
		border: 2px solid $clLimeade
		text-transform: uppercase
		font-weight: bold
		width: 240px
		height: 40px
		line-height: 40px
		padding: 0
		box-shadow: none
		background-color: $clWhite
		color: $clLimeade
		display: flex
		align-items: center
		justify-content: center
		.ui-button-text
			padding: 0
		&:focus
			background-color: $clLimeade
			color: $clWhite
		&:hover
			background-color: $clLimeade
			color: $clWhite
		.ui-button-icon-left
			display: none
.p-fileupload-content
	min-height: 150px
	padding: 1rem
	background-color: $clWhite
	border: 2px dashed $clAlto !important
	border-radius: 8px
	& .p-progressbar
		display: none
	& .ui-fileupload-files
		&>div
			display: flex
			flex-wrap: wrap
	& .ui-fileupload-img
		height: 100%
		width: 100%
	& .ui-fileupload-msg
		font-size: 0.8rem
		margin-top: 3rem
		display: inline-block
		width: 80%
		margin-left: 10%
		text-align: center
		color: $clLightSilverChalice
	& .ui-fileupload-img-wrap
		border: 1px solid $clAlto
		position: relative
		width: 105px
		height: 105px
		overflow: hidden
	& .ui-fileupload-img-remove
		position: absolute
		top: 0
		right: 0
		height: 20px
		width: 20px
		border: none
		outline: none
		z-index: 2
		background: black
		opacity: .7
		text-align: center
		line-height: 20px
		cursor: pointer
		color: $clAlto
		&:hover
			color: $clWhite

.p-fileupload-row
	display: none
