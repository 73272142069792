$checkboxHeight: 1.75rem	
.p-checkbox
	.p-checkbox-box 
		border-radius: 0
		height: $checkboxHeight
		line-height: $checkboxHeight
		width: $checkboxHeight
		display: flex
		justify-content: center
		align-items: center
		font-size: 1rem
		margin-right: 0.25rem
		border: 1px solid #efefef
		background-color: white
		&:not(.p-state-disabled):not(.p-highlight):hover
			border-color: #a8a8a8
			background-color: white
			color: #484848
.p-checkbox-label
	@extend .ui-label
	margin-bottom: 0
