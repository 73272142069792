/*button patch*/

/* .ui-button-patch .p-button-icon.p-button-icon-left{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-right: .75rem;
} */

/*button*/

.ui-button.ui-button-simple.ui-button-text-icon-left .ui-button-icon-left {
    left: 0;
    line-height: .75rem;
  }

.ui-button.ui-button-simple {
    position: relative;
    padding: 0;
    text-decoration: none !important;
    cursor: pointer;
    text-align: center;
    zoom: 1;
    overflow: visible;
}

/* .ui-button.ui-button-tiny.ui-button-text-icon-left{
    padding: 0.25rem 0.55rem;
} */

.ui-button.ui-button-tiny.ui-button-text-icon-left .p-button-icon.p-button-icon-left{
    padding-right:0.5rem;
}

.ui-button.ui-button-tiny .ui-button-text {
    padding: 0;
}

.ui-button.ui-button-tiny.ui-button-icon-only .ui-button-text {
    display: inline-block;
    width: 20px;
    height: 20px;
}

.ui-button .ui-button-text {
    display: block;
    line-height: normal;
}

.ui-button-text-icon-left .ui-button-text {
    padding: 0.25em 1em 0.25em 2.1em;
}

.ui-button-icon-only .ui-button-text, .ui-button-text-empty .ui-button-text {
    padding: .25em;
    text-indent: -9999999px;
}

.ui-button-icon-only .ui-button-icon-left {
    top: 50%;
    left: 50%;
    margin-top: -0.5em;
    margin-left: -0.5em;
    width: 1em;
    height: 1em;
}

.ui-button-icon-only .ui-button-icon-left,
.ui-button-text-icon-left .ui-button-icon-left,
.ui-button-text-icon-right .ui-button-icon-right {
    position: absolute;
    top: 50%;
    margin-top: -.5em;
    height: 1em;
}

.ui-button .ui-state-default {
    border: 1px solid #efefef;
    background-color: white;
    color: #484848;
}


.ui-button.ui-button-tiny.ui-button-icon-only {
    padding: 0.25em;
    width: 30px;
	margin-right: 0;
}

.p-button-icon.p-button-icon-left {
    left: 0;
    line-height: .75rem;
	margin-bottom: 2px;
}

.p-button {
    text-decoration: none;
}

.ui-button.ui-button-tiny.ui-button-text-icon-left {
    padding-left: 1rem;
}

/* .ui-button-simple .p-button-label {
    padding-left: 0.7rem;
} */

.ui-state-active {
    border-color: #a8a8a8;
    background-color: white;
    color: #484848;
}

/* .ui-state-default {
    border: 1px solid #efefef;
    background-color: white;
    color: #484848;
} */

/**************************************************/

/*panelmenu*/

.ui-panelmenu .ui-menuitem {
    overflow: hidden;
}

.ui-panelmenu .ui-panelmenu-header-link {
	display: block;
    text-decoration: none;
}

.ui-panelmenu .ui-panelmenu-header {
    /* margin: -1px 0 0 0; */
    zoom: 1;
}

.ui-panelmenu .ui-menuitem-icon {
    margin-right: 0.25em;
    vertical-align: middle;
}


.ui-panelmenu .ui-panelmenu-icon {
    vertical-align: middle;
}

.ui-panelmenu .ui-menuitem-link {
    padding: 0.25em;
    display: block;
    text-decoration: none;
}

.ui-panelmenu .ui-submenu-list {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: 1.5em;
}

.ui-panelmenu .ui-panelmenu-content>.ui-panelmenu-root-submenu>.ui-submenu-list {
    margin-left: 0;
}

.ui-panelmenu .ui-panelmenu-content-wrapper {
    overflow: hidden;
}

.ui-panelmenu .ui-menuitem-text {
    vertical-align: middle;
}


/*
    Details page

    Age restriction & Event format dropdown menu
*/
.select-field-wrap > .ui-corner-all {
    border-radius: 0;
}

.select-field-wrap .ui-state-default {
    border: 1px solid #efefef;
    background-color: white;
    color: #484848;
}

.select-field-wrap .ui-widget {
    font-family: "Heebo", "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 0.9rem;
}

.select-field-wrap .form-field .p-dropdown {
    width: 100% !important;
    margin-bottom: 1rem;
    border-radius: 0;
    border: 1px solid #efefef;
    background-color: white;
    color: #484848;
    font-family: "Heebo", "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 0.9rem;
	font-weight: 400;
    display: inline-block;
    vertical-align: middle;

    zoom: 1;
}

/* 
    Event details page
    banner image and listing image upload button 
*/

/* .p-element.file-upload.form-field.form-field__long-offset .p-component{
    position: relative;
    font-family: "Heebo", "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 0.9rem;
}

.p-fileupload.p-fileupload-advanced.p-component.ng-star-inserted .p-component{
    height: 0;
    padding: 0;
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: white;
    color: #484848;
    font-weight: bold;
}
.p-fileupload.p-fileupload-advanced.p-component.ng-star-inserted .p-fileupload-choose{
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2000;
    border-radius: 50px;
    box-sizing: border-box;
    border: 0;
    p-inputtextarea-transform: uppercase;
    font-weight: bold;
    width: 240px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    box-shadow: none;
    background-color: #5eb700;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
div.ui-fileupload-buttonbar.ui-widget-header.ui-corner-top span {
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    border-radius: 50px;
    box-sizing: border-box;
    border: 0;
    text-transform: uppercase;
    font-weight: bold;
    width: 240px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    box-shadow: none;
    background-color: #5eb700;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    margin-right: 0.25em;
    margin: 0 0.2rem;
    font-size: 0.9rem;
    overflow: hidden;
    text-decoration: none !important;
    cursor: pointer;
    text-align: center;
    zoom: 1;
}
span.p-ripple.p-element.p-button.p-component.p-fileupload-choose input {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    direction: ltr;
    cursor: pointer;
    z-index: 1;
    appearance: none;
    background-color: initial;
    align-items: baseline;
    color: inherit;
    text-overflow: ellipsis;
    white-space: pre;
    padding: initial;
    border: initial;
    overflow: hidden !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    -webkit-rtl-ordering: logical;
}
 */
/*p-dropdown elements*/
/* span.p-element {
    color: #484848;
    margin-bottom: 0;
    border: 0;
    font-weight: normal;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    padding-right: 2.5em;
    border-radius: 0;
    outline: medium none;
} */

i.p-dropdown-clear-icon.pi.pi-times {
    top: 55%;
    right: 2rem;
    color: #484848;
    font-size: 1em;
    height: 1em;
}

div.p-dropdown-trigger {
    padding: 0 1rem;
    border-left: 0;
    color: #d5d5d5;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    cursor: pointer;
    width: 1.5em;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-color: white;
}

span.p-dropdown-trigger-icon.pi.pi-chevron-down {
    top: 50%;
    left: 50%;
    margin-top: -.5em;
    margin-left: -.5em;
    position: absolute;
}

/*expanded p-drowpdown div*/
div.ng-trigger.ng-trigger-overlayAnimation.p-dropdown-panel.p-component.ng-star-inserted {
    z-index: 1003;
    transform: translateY(0px);
    opacity: 1;
    border: 1px solid #a8a8a8;
    border-top: 0;
    margin: 0 -1px;
    top: 42px !important;
    right: 0;
    box-shadow: none;
    background: #d5d5d5;
    border-radius: 0;
    color: #362b36;
    font-family: "Heebo", "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 0.9rem;
    height: auto;
}

ul.p-dropdown-items.ng-star-inserted {
    border: 0 none;
    border-radius: 0;
    background-color: #f2f5f7;
    color: #362b36;
    font-family: "Heebo", "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 0.9rem;
    outline: 0;
    line-height: 1.3;
    text-decoration: none;
    margin: 0;
    list-style: none;
}

li.p-ripple.p-element.p-dropdown-item {
    padding: 10px;
    background: #fcfcfc;
    color: #a3a3a3;
    font-weight: normal;
    border: 0 none;
    margin: 1px 0;
    text-align: left;
    border-radius: 0;
}
span.p-ripple.p-element.p-button.p-component.p-fileupload-choose span.pi.pi-plus.p-button-icon.p-button-icon-left {
    display: none;
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    height: 1em;
    left: 0.5em;
}

span.p-ripple.p-element.p-button.p-component.p-fileupload-choose span.p-button-label {
    padding: 0;
    display: block;
    line-height: normal;
}

div.p-fileupload.p-fileupload-advanced.p-component.ng-star-inserted div.p-fileupload-content{
    min-height: 150px;
    padding: 1rem;
    background-color: #fff;
    border: 1px solid #efefef;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: #362b36;
    transition: border-color .3s;
}

div.p-fileupload-content span.ui-fileupload-msg.ng-star-inserted{
    font-size: 0.8rem;
    margin-top: 3rem;
    display: inline-block;
    width: 80%;
    margin-left: 10%;
    text-align: center;
    color: #a3a3a3;
}
.p-fileupload-row {
	display:none !important;
}
/* .ui-corner-all {
    border-radius: 0;
} */

.ui-panelmenu .ui-menuitem-icon {
	vertical-align: baseline;
}


/* multi-select menu dashboard */
div.p-multiselect.p-component {
    border-radius: 0;
    border: 1px solid #efefef;
    background-color: white;
    color: #484848;
    font-size: 0.9rem;
    display: inline-block;
    position: relative;
    cursor: pointer;
}

div.p-multiselect-label {
    margin-bottom: 0;
    border: 0;
    background-color: #fff;
    padding: 10px;
    width: auto;
    border-radius: 0;
}

div.p-hidden-accessible input{
    font-family: "Heebo", "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 0.9rem;
}

div.p-multiselect-trigger {
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    cursor: pointer;
    width: 1.5em;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #efefef;
    background-color: white;
}

span.p-multiselect-trigger-icon.pi.pi-chevron-down {
    top: 50%;
    left: 50%;
    margin-top: -.5em;
    margin-left: -.5em;
    position: absolute;
}

div.ng-trigger.ng-trigger-overlayAnimation.p-multiselect-panel.p-component.ng-star-inserted {
    border-radius: 0;
    color: #362b36;
    font-family: "Heebo", "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 0.9rem;
    position: absolute;
}

div.p-multiselect-header.ng-star-inserted {
    border: 1px solid #efefef;
    background-color: white;
    color: #484848;
    font-weight: bold;
    margin-bottom: .3em;
    padding: .25em;
    position: relative;
    text-align: left;
    min-height: 2em;
    zoom: 1;
}

div.p-checkbox.p-component.ng-star-inserted {
    display: inline-block;
    vertical-align: middle;
    margin-right: .25em;
}

div.p-checkbox-box.p-highlight {
    border-radius: 0;
    height: 1.75rem;
    line-height: 1.75rem;
    width: 1.75rem;
    font-size: 1rem;
    text-align: center;
    border-color: #a8a8a8;
    background-color: white;
    color: #484848;
    border: 1px solid #efefef;
    font-family: "Heebo", "Trebuchet MS", Arial, Helvetica, sans-serif;
}

div.p-checkbox-box.p-highlight span {
    display: block;
}

div.p-multiselect-filter-container.ng-star-inserted {
    display: inline-block;
    vertical-align: middle;
    width: 65%;
}

div.p-multiselect-filter-container.ng-star-inserted input {
    padding: .125em .125em .125em 1.25em;
    font-weight: normal;
    font-family: "Heebo", "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 0.9rem;
    background-color: #fff;
    border: 1px solid #efefef;
    margin: 0;
    margin-bottom: 1rem;
    color: #484848;
    border-radius: 0;
    outline: medium none;
}

div.p-multiselect-filter-container.ng-star-inserted span {
    top: 0.25em;
    left: 0.125em;
}

button.p-ripple.p-element.p-multiselect-close.p-link.ng-star-inserted {
    color: #484848;
    position: absolute;
    right: .375em;
    top: .375em;
    display: block;
    border: 0 none;
    cursor: pointer;
}

div.p-multiselect-items-wrapper {
    color: #362b36;
    font-family: "Heebo", "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 0.9rem;
}

.p-multiselect-item {
	border: 0 none;
    cursor: pointer;
    font-weight: normal;
    margin: 1px 0;
    padding: 0.125em 0.25em;
    text-align: left;
    white-space: nowrap;
    display: block;
    position: relative; 
}
/*General*/

.ui-widget {
    font-family: "Heebo", "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 0.9rem;
  }
  
.ui-widget, .ui-widget * {
    box-sizing: border-box;
}

.p-inputtext.p-component.p-element {
	border: 1px solid #efefef;
	padding: 10px;
	box-sizing: border-box;
	margin-bottom: 1rem;
	color: #484848;
	background-color: #fff;
	outline: medium none;
}

.event-control-panel-content .event-tickets .p-inputtext.p-component.p-element {
	width: 100%;
}

.ticket-settings-customisations .p-inputtext.p-component.p-element {
	width: 100%;
}

.details-settings-messages .p-inputtext.p-component.p-element {
	width: 100%;
}

.tickets-table__row .p-inputtext.p-component.p-element {
	width: 100%;
}

.p-component-overlay {
    background-color: rgba(102,102,102,.8);
/*     opacity: .50;
 */    filter: Alpha(Opacity=50);
}

.manage-order-button .p-button {
	padding: 0.25rem 1rem !important
}

@media screen and (max-width: 40em) {
    .ui-sm-1,
    .ui-sm-2,
    .ui-sm-3,
    .ui-sm-4,
    .ui-sm-5,
    .ui-sm-6,
    .ui-sm-7,
    .ui-sm-8,
    .ui-sm-9,
    .ui-sm-10,
    .ui-sm-11,
    .ui-sm-12 {
        padding: 0.5em;
    }

    .ui-sm-1 {
        width: 8.3333%;
    }

    .ui-sm-2 {
        width: 16.6667%;
    }

    .ui-sm-3 {
        width: 25%;
    }

    .ui-sm-4 {
        width: 33.3333%;
    }

    .ui-sm-5 {
        width: 41.6667%;
    }

    .ui-sm-6 {
        width: 50%;
    }

    .ui-sm-7 {
        width: 58.3333%;
    }

    .ui-sm-8 {
        width: 66.6667%;
    }

    .ui-sm-9 {
        width: 75%;
    }

    .ui-sm-10 {
        width: 83.3333%;
    }

    .ui-sm-11 {
        width: 91.6667%;
    }

    .ui-sm-12 {
        width: 100%;
    }
    
    .ui-sm-offset-12 {
      margin-left: 100%;
    }

    .ui-sm-offset-11 {
      margin-left: 91.66666667%;
    }

    .ui-sm-offset-10 {
      margin-left: 83.33333333%;
    }

    .ui-sm-offset-9 {
      margin-left: 75%;
    }

    .ui-sm-offset-8 {
      margin-left: 66.66666667%;
    }

    .ui-sm-offset-7 {
      margin-left: 58.33333333%;
    }

    .ui-sm-offset-6 {
      margin-left: 50%;
    }

    .ui-sm-offset-5 {
      margin-left: 41.66666667%;
    }

    .ui-sm-offset-4 {
      margin-left: 33.33333333%;
    }

    .ui-sm-offset-3 {
      margin-left: 25%;
    }

    .ui-sm-offset-2 {
      margin-left: 16.66666667%;
    }

    .ui-sm-offset-1 {
      margin-left: 8.33333333%;
    }

    .ui-sm-offset-0 {
      margin-left: 0%;
    }
}

@media screen and (min-width: 40.063em) {
    .ui-md-1,
    .ui-md-2,
    .ui-md-3,
    .ui-md-4,
    .ui-md-5,
    .ui-md-6,
    .ui-md-7,
    .ui-md-8,
    .ui-md-9,
    .ui-md-10,
    .ui-md-11,
    .ui-md-12 {
        padding: 0.5em;
    }

    .ui-md-1 {
        width: 8.3333%;
    }

    .ui-md-2 {
        width: 16.6667%;
    }

    .ui-md-3 {
        width: 25%;
    }

    .ui-md-4 {
        width: 33.3333%;
    }

    .ui-md-5 {
        width: 41.6667%;
    }

    .ui-md-6 {
        width: 50%;
    }

    .ui-md-7 {
        width: 58.3333%;
    }

    .ui-md-8 {
        width: 66.6667%;
    }

    .ui-md-9 {
        width: 75%;
    }

    .ui-md-10 {
        width: 83.3333%;
    }

    .ui-md-11 {
        width: 91.6667%;
    }

    .ui-md-12 {
        width: 100%;
    }
    
    .ui-md-offset-12 {
      margin-left: 100%;
    }

    .ui-md-offset-11 {
      margin-left: 91.66666667%;
    }

    .ui-md-offset-10 {
      margin-left: 83.33333333%;
    }

    .ui-md-offset-9 {
      margin-left: 75%;
    }

    .ui-md-offset-8 {
      margin-left: 66.66666667%;
    }

    .ui-md-offset-7 {
      margin-left: 58.33333333%;
    }

    .ui-md-offset-6 {
      margin-left: 50%;
    }

    .ui-md-offset-5 {
      margin-left: 41.66666667%;
    }

    .ui-md-offset-4 {
      margin-left: 33.33333333%;
    }

    .ui-md-offset-3 {
      margin-left: 25%;
    }

    .ui-md-offset-2 {
      margin-left: 16.66666667%;
    }

    .ui-md-offset-1 {
      margin-left: 8.33333333%;
    }

    .ui-md-offset-0 {
      margin-left: 0%;
    }
}

@media screen and (min-width: 64.063em) {
    .ui-lg-1,
    .ui-lg-2,
    .ui-lg-3,
    .ui-lg-4,
    .ui-lg-5,
    .ui-lg-6,
    .ui-lg-7,
    .ui-lg-8,
    .ui-lg-9,
    .ui-lg-10,
    .ui-lg-11,
    .ui-lg-12 {
        padding: 0.5em;
    }

    .ui-lg-1 {
        width: 8.3333%;
    }

    .ui-lg-2 {
        width: 16.6667%;
    }

    .ui-lg-3 {
        width: 25%;
    }

    .ui-lg-4 {
        width: 33.3333%;
    }

    .ui-lg-5 {
        width: 41.6667%;
    }

    .ui-lg-6 {
        width: 50%;
    }

    .ui-lg-7 {
        width: 58.3333%;
    }

    .ui-lg-8 {
        width: 66.6667%;
    }

    .ui-lg-9 {
        width: 75%;
    }

    .ui-lg-10 {
        width: 83.3333%;
    }

    .ui-lg-11 {
        width: 91.6667%;
    }

    .ui-lg-12 {
        width: 100%;
    }
    
    .ui-lg-offset-12 {
      margin-left: 100%;
    }

    .ui-lg-offset-11 {
      margin-left: 91.66666667%;
    }

    .ui-lg-offset-10 {
      margin-left: 83.33333333%;
    }

    .ui-lg-offset-9 {
      margin-left: 75%;
    }

    .ui-lg-offset-8 {
      margin-left: 66.66666667%;
    }

    .ui-lg-offset-7 {
      margin-left: 58.33333333%;
    }

    .ui-lg-offset-6 {
      margin-left: 50%;
    }

    .ui-lg-offset-5 {
      margin-left: 41.66666667%;
    }

    .ui-lg-offset-4 {
      margin-left: 33.33333333%;
    }

    .ui-lg-offset-3 {
      margin-left: 25%;
    }

    .ui-lg-offset-2 {
      margin-left: 16.66666667%;
    }

    .ui-lg-offset-1 {
      margin-left: 8.33333333%;
    }

    .ui-lg-offset-0 {
      margin-left: 0%;
    }
}

@media screen and (min-width: 90.063em) {
    .ui-xl-1,
    .ui-xl-2,
    .ui-xl-3,
    .ui-xl-4,
    .ui-xl-5,
    .ui-xl-6,
    .ui-xl-7,
    .ui-xl-8,
    .ui-xl-9,
    .ui-xl-10,
    .ui-xl-11,
    .ui-xl-12 {
        padding: 0.5em;
    }

    .ui-xl-1 {
        width: 8.3333%;
    }

    .ui-xl-2 {
        width: 16.6667%;
    }

    .ui-xl-3 {
        width: 25%;
    }

    .ui-xl-4 {
        width: 33.3333%;
    }

    .ui-xl-5 {
        width: 41.6667%;
    }

    .ui-xl-6 {
        width: 50%;
    }

    .ui-xl-7 {
        width: 58.3333%;
    }

    .ui-xl-8 {
        width: 66.6667%;
    }

    .ui-xl-9 {
        width: 75%;
    }

    .ui-xl-10 {
        width: 83.3333%;
    }

    .ui-xl-11 {
        width: 91.6667%;
    }

    .ui-xl-12 {
        width: 100%;
    }
    
    .ui-xl-offset-12 {
      margin-left: 100%;
    }

    .ui-xl-offset-11 {
      margin-left: 91.66666667%;
    }

    .ui-xl-offset-10 {
      margin-left: 83.33333333%;
    }

    .ui-xl-offset-9 {
      margin-left: 75%;
    }

    .ui-xl-offset-8 {
      margin-left: 66.66666667%;
    }

    .ui-xl-offset-7 {
      margin-left: 58.33333333%;
    }

    .ui-xl-offset-6 {
      margin-left: 50%;
    }

    .ui-xl-offset-5 {
      margin-left: 41.66666667%;
    }

    .ui-xl-offset-4 {
      margin-left: 33.33333333%;
    }

    .ui-xl-offset-3 {
      margin-left: 25%;
    }

    .ui-xl-offset-2 {
      margin-left: 16.66666667%;
    }

    .ui-xl-offset-1 {
      margin-left: 8.33333333%;
    }

    .ui-xl-offset-0 {
      margin-left: 0%;
    }
}

input:disabled.p-component {
	opacity:35% !important
}

.event-streaming-wrap .p-dialog-mask {
	z-index: 999 !important;
}
	

.import-contacts-content .p-accordion-header {
	margin-bottom: 10px;
}