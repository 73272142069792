@import '../typography.sass' 
@import '../colors.sass' 

.ui-text
	font-size: $fontSize
	font-family: $fontFamily
	color: $clTundora
	&.ui-text-small
		font-size: $fontSizeSmall
	&.ui-text-medium
		font-size: $fontSizeMedium
	&.ui-text-large
		font-size: $fontSizeLarge
	&.ui-text-bold
		font-weight: bold
	&.ui-text-greyed
		color: $clSilverChalice
	&.ui-text-bold
		font-weight: bold
	&.ui-text-normal
		font-weight: normal
	&.ui-text-block
		display: block
	&.ui-text-error
		color: $clCrimson