.ui-p
	font-family: $fontFamily
	color: $clTundora
	font-size: 0.9rem
	margin: .5rem 0
.ui-span
	font-family: $fontFamily
	color: $clTundora
	font-size: 0.9rem
.ui-ul
	margin: 0.5rem 0
	font-family: $fontFamily
	color: $clTundora
	font-size: 0.9rem