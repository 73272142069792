.user-tour-step
	.ngxp__container
		max-width: 350px
		background-color: $clLightAlabaster
		border-radius: 0
		box-shadow: 0px 0px 2px 0px $clLightSilver
		border: 1px solid $clAlto
		border-top: 1px solid $clFlushOrange
		padding: 1rem
		font-family: $fontFamily
		.ngxp-title
			margin: 0
			font-weight: bold
			font-size: 1rem
		.ngxp-content
			font-size: .9rem
			margin: .5rem 0
			margin-bottom: 1rem
			a
				color: $clLimeade
				text-decoration: none
			ul
				padding-left: 1.5rem
		.tour-step-navigation
			display: flex
			justify-content: center
			app-button
				&:last-child
					margin-left: 2rem
		& > .ngxp__arrow
			border-color: $clDarkSilverChalice