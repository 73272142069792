@import '../colors.sass' 
@import '../variables.sass' 
@import '../typography.sass' 

.ui-label
	font-size: 0.8rem
	display: inline-flex
	align-items: center
	margin-bottom: 0.25rem
	color: $clLightSilverChalice
	font-family: $fontFamily
	.ui-label-icon, i
		margin-left: 0.5rem
	&.bold
		font-weight: bold
		font-size: 0.9rem
	&.medium
		font-size: 0.9rem
	&.black
		color: $clTundora
	&.error
		color: $clCrimson
	&.large
		font-size: 1rem
	&.block
		display: block
	&.offset
		margin-bottom: 1rem
.ui-label-icon.warning
	color: $clFlushOrange
.ui-required
	color: $clFlushOrange
	font-size: 0.8rem
	display: block
	margin-top: -2px
	margin-left: 1px
.ui-inputtext
	background-color: $clWhite
	border: 1px solid $clGallery
	padding: 10px
	box-sizing: border-box
	margin-bottom: 1rem
	color: $clTundora
	&:enabled:hover, &:focus
		border: 1px solid $clDarkSilverChalice
		box-shadow: none
		& + .ui-input-prefix
			border: 1px solid $clDarkSilverChalice
			border-right: none
	&::placeholder
		color: $clAlto
.ui-input-prefix
	font-family: $fontFamily
	background-color: $clWhite
	color: $clTundora
	font-size: 0.7rem
	padding: 0 0.25rem
	font-weight: normal
	margin-bottom: 1rem
	border: 1px solid $clGallery
	border-right: none
	display: flex
	align-items: center
.ui-error-msg
	position: absolute
	bottom: 0
	left: 0
	font-family: $fontFamily
	color: $clCrimson
	display: block
	width: 100%
	white-space: nowrap
	text-overflow: ellipsis
	overflow: hidden
	font-size: 0.7rem
	line-height: 0.7rem
.ui-inputtext-prefix
	position: absolute
	top: 32px
	font-family: $fontFamily
	font-weight: bold
	color: $clTundora
	left: 10px
