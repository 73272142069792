.p-multiselect
	margin-bottom: 1rem
	.p-multiselect-header
		display: none
	.ui-multiselect-label
		@extend .ui-inputtext
		margin-bottom: 0
		color: $clAlto
		border: 0
	.p-multiselect-panel
		@include ui-dropdown-panel
		padding: 0
		z-index: 1003 !important
		.p-multiselect-items-wrapper
			padding: 0
		.p-multiselect-items
			@include ui-dropdown-items
		.p-multiselect-item
			@include ui-dropdown-item
			outline-color: transparent
	.p-multiselect-trigger
		@include ui-dropdown-trigger
	&-items
		background-color: #f2f5f7
.p-dropdown-value
	.p-multiselect-label
		color: $clTundora