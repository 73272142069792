.ui-editor-container
	.ql-container.ql-snow, .ui-editor-toolbar
		border: 1px solid $clGallery
		box-sizing: border-box
	&:focus, &:hover
		.ql-container.ql-snow, .ui-editor-toolbar
			border: 1px solid $clDarkSilverChalice
			box-sizing: border-box
		.ui-editor-toolbar
			border-bottom: 0
	.ql-toolbar.ql-snow + .ql-container.ql-snow
		border-top: none
	.ui-editor-toolbar
		background: $clAlto
		color: $clLimeade
		font-family: $fontFamily
		border-bottom: 0
.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected
	stroke: $clLimeade
	& *
		stroke: $clLimeade !important
.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected
	color: $clLimeade
.ql-editor.ql-blank::before
	color: $clAlto
	font-size: 0.9rem
.ui-editor-content
	border-top: 0
	background-color: white
	font-family: $fontFamily