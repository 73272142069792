.notifications-overlay
	position: fixed
	bottom: 90px
	right: 50px
	z-index: 1600
	@media screen and (max-width: 640px)
		display: none
		max-width: 300px
	.notification
		position: relative
		display: flex
		max-width: 400px
		min-width: 400px
		background: $clWhite
		border: 1px solid #F0F0F0
		box-shadow: 0px 4px 6px $clLightSilver
		padding-left: 0
		margin: 0.5rem 0
		border-radius: 8px
		.notification-icon-wrap
			display: flex
			justify-content: center
			align-items: center
			flex: 1
			font-size: 1.8rem
			color: $clWhite
			border-radius: 8px 0 0 8px
		.notification-content
			padding: 0.75rem// 1rem
			display: flex
			flex-direction: column
			justify-content: center
			flex: 5
			// margin-left: 0.5rem
			.notification-title
				font-family: $fontFamily
				color: $clTundora
				font-weight: bold
				font-size: 1rem
				@media screen and (max-width: 640px)
					font-size: .9rem
			.notification-desc
				font-family: $fontFamily
				font-size: 0.85rem
				//color: $clLightSilverChalice
				color: $clTundora
				@media screen and (max-width: 640px)
					font-size: .9rem
		.notification-close, .notification-retry
			position: absolute
			font-size: 0.95rem
			right: 0.5rem
			cursor: pointer
			color: $clLightGray
			&:hover
				color: $clGray
		.notification-close
			top: 0.4rem
		.notification-retry
			bottom: 0.4rem
			color: $clLimeade
			&:hover
				color: $darkLimeade
	.notification__success
		//border: 1px solid $clShowFlurry
		//background: $clShowFlurry
		// border-top: 1px solid $clLimeade
		.notification-icon-wrap
			background: $clLimeade
			outline: 1px solid $clLimeade
	.notification__error
		//border: 1px solid $clCosmos
		//background: $clCosmos
		// border-top: 1px solid $clCrimson
		.notification-icon-wrap
			background: $clCrimson
			outline: 1px solid $clCrimson
	.notification__info
		// border-top: 1px solid $clFlushOrange
		.notification-icon-wrap
			background: $clFlushOrange
			outline: 1px solid $clFlushOrange
