.p-tabmenu
	border: 0
	background: transparent
	padding: 0
	.p-tabmenu-nav
		padding: 0
		border: 0
		display: flex
		background: transparent
		.p-tabmenuitem
			margin: 0
			border: 0
			background: transparent
			font-weight: normal
			font-family: $fontFamily
			outline-color: transparent
			border-bottom: 1px solid $clGallery
			flex: 1
			text-align: center
			a
				width: 100%
				padding: 0
			&-header
				padding: .25rem .5rem
			&.p-highlight
				border-color: $clFlushOrange
				a
					color: $clFlushOrange
		& li:not(.p-highlight):hover
			background-color: transparent
			border-bottom: 1px solid $clGallery
			a
				color: $clFlushOrange
.ui-tabmenuitem-header
	padding: .25rem .5rem
	width: 100%
	white-space: nowrap
	color: #484848