/* You can add global styles to this file, and also import other style files */
@import "./theme/index.sass"
@import './ThemeSet/variables.sass'

body, html
	margin: 0
	height: 100%
	width: 100%
	background: #FCFCFC !important
	color: #485868 !important
	font-family: $fontFamily !important
.field-sub-wrap
	display: flex
	justify-content: space-between
	@media screen and (max-width: 640px)
		flex-direction: column
		justify-content: flex-start
		align-items: flex-start !important
		&>*
			margin-bottom: 0.5rem
.field-sub-wrap__offset
	margin-bottom: 1rem
.field-sub-desc
	font-size: 0.85rem
	display: inline-flex
	align-items: baseline
	span
		padding-left: .5rem
.field__composed
	display: flex
	flex-direction: row
	align-items: center
	&>*
		flex: 1
	&>:first-child
		margin-right: 0.5rem
	&>:last-child
		margin-left: 0.5rem
	@media screen and (max-width: 640px)
		flex-direction: column
		&>*
			width: 100%
		&>:first-child
			margin-right: 0
		&>:last-child
			margin-left: 0
			margin-bottom: 0.5rem
.ql-tooltip.ql-editing
	left: 50% !important
	transform: translateX(-50%)
	top: 0 !important
	margin-top: unset !important
.warning-wrap
	font-family: $fontFamily
	display: flex
	align-items: center
	color: $clFlushOrange
	margin-bottom: 1rem
	margin-top: 0.5rem
	::ng-deep i
		color: $clFlushOrange
		margin-right: 0.5rem
		height: 20px
		width: 20px
		font-size: 20px
.dnd-sortable-drag
	opacity: 0.3
	border: 1px dashed $clLightSilverChalice
.cdk-overlay-container
	z-index: 1500
.overlay-host-item
	position: relative
.overlay-host-item-table
	position: inherit
.event-form-wrap
	width: 100%
	display: flex
	justify-content: center
	.event-form
		width: 100%
.event-form-wrap__centered
	.event-form
		width: 60%
		@media screen and (max-width: 1440px)
			width: 70%
		@media screen and (max-width: 1240px)
			width: 100%
.event-form-wrap__shifted.event-form-wrap__centered
	.event-form
		margin-right: 220px
		@media screen and (max-width: 1440px)
			margin-right: 100px
		@media screen and (max-width: 1240px)
			margin-right: 0
.event-form
	min-height: 10rem
	height: 100%
	.event-form-content, .event-form-submit
		display: flex
		justify-content: center
	.event-form-submit
		justify-content: flex-start
		@media screen and (max-width: 640px)
			justify-content: center
	.event-form-header-control
		border-bottom: 1px solid $clAlto
		background-color: $clWhite
	.event-form-submit-button
		display: flex
		justify-content: flex-start
		@media screen and (max-width: 640px)
			justify-content: center

#designerLoader
	position: absolute
.sidebar-active-link span
	color: $clLimeade

#article-content
	img
		max-width: 100%

.error-modal
	.ui-dialog-titlebar
		display: none
	.ui-dialog
		border-top: 1px solid $clCrimson
.error-content
	text-align: center
	font-family: $fontFamily
	.error-icon
		color: $clTundora
	.error-title
		color: $clTundora
		margin: 0.5rem 0
	.error-desc
		font-size: 1rem
		color: $clGray
	.error-msg
		color: $clTundora
	ul
		font-style: italic
		text-align: left
.g-share-my-event-button
	cursor: pointer
	text-decoration: none
	border: none
	background: none
	padding: 0
	font-size: 20px
	color: #A5A5AA
.social-sharing-btn
	font-size: 1.5rem
	margin: .25rem
	cursor: pointer
	text-decoration: none
.facebook-share-btn
	color: #4267B2
	&:enabled:hover, &:focus
		color: #4267B2
.twitter-share-btn
	color: #1DA1F2
	&:enabled:hover, &:focus
		color: #1DA1F2
.linkedin-share-btn
	color: #0274B3
	&:enabled:hover, &:focus
		color: #0274B3
.pinterest-share-btn
	color: #BD081C
	&:enabled:hover, &:focus
		color: #BD081C
.mail-share-btn
	color: $clLightGray
	&:enabled:hover, &:focus
		color: $clLightGray
.whatsapp-share-btn
	color: #00E676
	&:enabled:hover, &:focus
		color: #00E676
app-button
	display: inline-flex
.tickets-table__row-preview
	font-family: $fontFamily
	font-size: 1rem
	padding: 0 1rem

//New angular rebuild

.g-flat-link
	text-decoration: none !important
	color: inherit !important

.g-link
	color: $clLimeade !important
	text-decoration: none !important
	cursor: pointer
	&:hover
		text-decoration: underline !important

// buttons
.g-primary-button
	background: $clLimeade
	color: $clWhiteSmoke
	width: 242px
	height: 48px
	border-radius: 8px
	font-size: 14px
	font-weight: 500
	letter-spacing: 1px
	border: none

	&:hover
		background: $darkLimeade

.g-primary-button.disabled
	background: $clLightSilverChalice

.g-secondary-button
	background: $clWhite
	color: $clLimeade
	width: 242px
	height: 48px
	border-radius: 8px
	font-size: 14px
	font-weight: 500
	letter-spacing: 1px
	border: 1px solid $clLimeade
	outline: none
	cursor: pointer

.g-flat-button
	background: none
	border: none
	padding: 8px
	text-align: center
	text-decoration: none
	color: #485868

.g-secondary-button.disabled
	color: $clLightSilverChalice !important
	border: 1px solid $clLightSilverChalice !important
	cursor: not-allowed

.g-secondary-button:hover
	color: #5eb700e0

.g-warning-button
	background: $clFlushOrange
	color: $clWhiteSmoke
	width: 242px
	height: 48px
	border-radius: 8px
	font-size: 14px
	font-weight: 500
	letter-spacing: 1px
	border: none

.g-greyed-button
	background: #F0F0F4
	color: #485868
	border-radius: 8px
	display: flex
	width: 240px
	height: 40px
	justify-content: center
	align-items: center
	border: none
	gap: 8px
	font-weight: 500
	font-size: 14px
	&:hover
		background: #D4D4DB

.g-disabled
	color: $clLightSilverChalice
	border: 1px solid $clLightSilverChalice

.g-pointer
	cursor: pointer

.g-move
	cursor: move

// fonts
.g-f-12
	font-size: 12px

.g-f-14
	font-size: 14px

.g-f-16
	font-size: 16px

.g-h3
	color: #485868
	text-align: center
	font-family: Heebo
	font-size: 24px
	font-style: normal
	font-weight: 500
	line-height: normal
	text-transform: capitalize

.g-subtitle
	color: #485868
	font-family: Heebo
	font-size: 16px
	font-style: normal
	font-weight: 500
	line-height: normal
	letter-spacing: 0.024px

// forms
.g-form-label
	font-size: 14px
	color: $clLightHeaderGray
	font-weight: 500
	padding-bottom: 8px
	display: flex
	gap: 2px
	align-items: center
	margin-left: 2px

.g-form-container
	display: flex
	flex-direction: column
	align-items: center

.g-double-input-container
	display: flex
	justify-content: center
	flex-wrap: wrap
	gap: 24px
	margin-bottom: 24px
	width: 100%

.g-double-input
	flex-basis: calc(50% - 12px)

.g-full-width
	flex-basis: 100%

.g-half-width
	flex-basis: calc(50% - 12px)

.g-fixed-footer
	position: fixed
	bottom: 0
	width: 100%
	background: $clWhite

.g-account-container
	overflow-y: auto
	scrollbar-gutter: stable
	height: calc(100% - $headerHeight)
	display: flex
	flex-direction: column
	@media (max-width: 1140px)
		height: calc(100% - $smallHeaderHeight)

.g-account-content
	display: flex
	flex-direction: column
	max-width: 1000px
	width: 100%
	margin: 0 auto
	&.web
		padding: 16px 32px 142px 32px
	&.mobile
		padding: 0 16px 142px 16px

.g-input-container
	margin-bottom: 24px

// Dialogs
.g-standard-dialog
	width: 600px !important
	overflow: auto
	max-height: 95% !important
	border-radius: 8px

.g-standard-dialog-height-8
	max-height: 800px !important
	@extend .g-standard-dialog

.g-full-page-dialog
	max-width: 100vw !important
	width: 100%
	height: 100vh

.g-wide-dialog
	width: 100%
	max-height: 90vh !important
	height: auto
	border-radius: 8px

.g-ultra-wide-dialog
	width: 100%
	max-width: 95vw !important
	height: min-content

.g-append-input
	padding-right: 40px !important

// Error
.g-error-input
	color: $clCrimson
	font-size: 12px
	padding-top: 4px
	padding-left: 8px

.g-warning-input
	color: $clFlushOrange
	font-size: 12px
	padding-top: 4px
	paddibng-left: 8px

// Spinner
.g-button-spinner
	height: 30px !important
	width: 30px !important
	margin: auto !important

.g-button-spinner.mat-mdc-progress-spinner
	--mdc-circular-progress-active-indicator-color: #fff !important

// SideNav
.g-mobile-sidenav
	width: 80%
	height: 100%

// Colour
.g-orange
	color: $clFlushOrange

.g-red
	color: $clCrimson

.g-green
	color: $clLimeade !important

// Snackbar
.g-success-snackbar.mat-mdc-snack-bar-container
	--mat-mdc-snack-bar-button-color: #fff
	--mdc-snackbar-container-color: #5eb700

.g-error-snackbar.mat-mdc-snack-bar-container
	--mat-mdc-snack-bar-button-color: #fff
	--mdc-snackbar-container-color: #d52222

.g-info-snackbar.mat-mdc-snack-bar-container
	--mat-mdc-snack-bar-button-color: #fff
	--mdc-snackbar-container-color: #FF7C00

.g-icon-center
	.mat-icon
		display: flex !important

// Hover
.g-highlight:hover
	color: $clLimeade

// Horizontal Line
.g-light-line
	color: #cbcbcb

.g-info-block
	transform-origin: top
	border: 1px solid #F0F0F0
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05)
	border-radius: 8px
	max-width: 1000px
	display: flex
	align-items: center
	overflow: hidden
	padding-left: 16px
	padding-right: 16px
	margin-bottom: 16px

.g-info-block-msg
	flex: 11
	font-family: $fontFamily
	font-size: 0.9rem
	margin: 0
	@media screen and (max-width: 640px)
		flex: 8

.g-info-block-icon
	flex: 1
	text-align: center
	color: $clLightSilverChalice
	margin-left: -0.5rem
	@media screen and (max-width: 640px)
		font-size: 1rem

// Iframe
.g-iframe
	height: 100%
	width: 100%

.g-sticky-desktop-header
	position: sticky
	top: 0px

// Datepicker
.g-datepicker-button
	width: 80px
	height: 36px
