.ui-switch-wrap
	display: flex
	align-items: center
	&>label
		margin-right: 0.5rem
.ui-switch 
	position: relative
	display: inline-block
	//width: 40px
	//height: 22px
	width: 46px
	height: 22px
	border-radius: 30px
	border: 1px solid $clGallery
	overflow: hidden
	input 
		display: none

.ui-switch-slider 
	position: absolute
	cursor: pointer
	top: 0
	left: 0
	right: 0
	bottom: 0
	background-color: $clLimeade
	transition: .4s
	font-size: 0.7rem
	font-family: $fontFamily
	font-weight: bold
	color: $clTundora
	line-height: 22px
	user-select: none
	&:before 
		position: absolute
		content: ""
		border-radius: 50px
		//height: 18px
		//width: 18px
		//left: 2px
		//bottom: 2px
		height: 22px
		width: 23px
		left: 0
		bottom: 0
		background-color: $clWhite
		transition: .4s
input 
	&:checked + .ui-switch-slider 
		background-color: $clLimeade
		text-align: left
		padding-left: 3px
		&:before 
			transform: translateX(23px)
	&:not(:checked) + .ui-switch-slider
		background: $clAlto
		text-align: right
		padding-right: 4px
	&:focus + .ui-switch-slider 
		box-shadow: 0 0 1px $clLimeade
	&:checked:disabled + .ui-switch-slider
		opacity: .5