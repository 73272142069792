.ui-message-block
	width: 100%
	box-sizing: border-box
	background-color: $clWhite
	border-top: 1px solid $clAlto
	padding: 1rem
	margin-bottom: 1rem
	display: flex
	align-items: center
	&.greyed
		background-color: $clGallery
	.ui-message-desc
		flex: 11
		color: $clLightSilverChalice
		font-family: $fontFamily
		font-size: 0.9rem
		margin: 0
		@media screen and (max-width: 640px)
			flex: 8
		&.bold
			font-weight: bold
	.ui-message-icon
		flex: 1
		text-align: center
		color: $clLightSilverChalice
		margin-left: -0.5rem
		@media screen and (max-width: 640px)
			font-size: 1rem
		&.info
			color: $clLightSilverChalice
		&.warning
			color: $clFlushOrange
		&.error
			color: $clCrimson
		&__sticky
			flex: 0
			margin-right: 0.5rem