@mixin ui-dropdown-panel
	border: 1px solid $clDarkSilverChalice
	border-top: 0
	margin: 0 -1px
	top: 42px !important
	right: 0
	box-shadow: none
	background: $clAlto
@mixin ui-dropdown-items
	padding: 0
@mixin ui-dropdown-item
	padding: 10px
	background: $clLightAlabaster
	color: $clLightSilverChalice
	&:not(.ui-state-disabled):hover
		color: $clLightSilverChalice
		background: $clGallery
	&:not(.ui-state-highlight):not(.ui-state-disabled):hover
		color: $clLightSilverChalice
		background: $clGallery
@mixin ui-dropdown-trigger
	padding: 0 1rem
	display: flex
	align-items: center
	border-left: 0
	color: $clAlto

.ui-dropdown
	margin-bottom: 1rem
	.ui-dropdown-label
		margin-bottom: 0
		color: $clAlto
	.ui-dropdown-trigger
		@include ui-dropdown-trigger
	&.ui-state-focus
		.ui-dropdown-trigger
			color: $clTundora
		.ui-dropdown-clear-icon
			color: $clTundora
	.ui-dropdown-panel
		@include ui-dropdown-panel
		.ui-dropdown-items-wraper
		.ui-dropdown-items
			@include ui-dropdown-items
		.ui-dropdown-item
			@include ui-dropdown-item
	.ui-dropdown-clear-icon
		top: 55%
		right: 2rem
		color: $clTundora
.ui-dropdown-value
	.ui-dropdown-label
		color: $clTundora