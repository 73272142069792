$radioHeight: 1.75rem
$radioMargin: .75rem
.p-radiobutton
	margin-bottom: $radioMargin
	margin-right: .5rem
	vertical-align: middle
.p-radiobutton-box 
	height: $radioHeight
	line-height: $radioHeight
	width: $radioHeight
	display: flex
	justify-content: center
	align-items: center
	font-size: 1rem
	border: 1px solid $clGallery
	border-radius: 100%
	&:hover
		border: 1px solid $clDarkSilverChalice
	&.p-highlight
		border: 1px solid $clDarkSilverChalice
		& .p-radiobutton-icon
			font-family: 'primeicons'
			speak: none
			font-style: normal
			font-weight: normal
			font-variant: normal
			text-transform: none
			line-height: 1
			-webkit-font-smoothing: antialiased
			padding-top: 1px
			&:before
				content: "\e911"
.p-radiobutton-icon
	font-size: 0.5rem
	color: $clTundora
.p-radiobutton-label
	@extend .ui-label
	margin-bottom: $radioMargin
	
