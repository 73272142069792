.p-tabview
	border: 0
	background: transparent
	padding: 0
	.p-tabview-nav .ui-tabview-nav__headless
		display: none
	&.p-tabview-top>.p-tabview-nav
		padding: 0 .5rem
	.p-tabview-panel
		padding: .5rem
	.p-tabview-nav
		border: 0
		background-color: transparent
		border-bottom: 1px solid $clGallery
		li
			background: transparent
			font-weight: normal
			text-transform: uppercase
			font-family: $fontFamily
			border: 0
			outline-color: transparent
			a
				color: #484848
			&.p-highlight 
				border-bottom: 1px solid $clFlushOrange
				a
					color: $clFlushOrange
			&:not(.p-highlight):not(.ui-state-disabled):hover
				background: transparent
				border-color: $clGallery

.p-tabview .p-tabview-nav li a
	float: left
	padding: 0.7em 1.05em
	text-decoration: none

ul.p-tabview-nav
	margin: 0 .5rem

