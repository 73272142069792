$small-phone-up-bound: 350px
$phone-up-bound: 600px
$tablet-up-bound: 900px
$laptop-up-bound: 1200px
$desktop-up-bound: 1400px
$wide-desktop-up-bound: 1800px

@mixin for-size-s($min-width, $max-width)
	@media (min-width: $min-width) and (max-width: #{$max-width - 1})
			@content
@mixin for-size($size)
	@if $size == small-phone-only
		@media (max-width: #{$small-phone-up-bound - 1})
			@content
	@if $size == phone-only
		@media (max-width: #{$phone-up-bound - 1})
			@content
	@else if $size == phone-up
		@media (min-width: $phone-up-bound)
			@content
	@else if $size == tablet-only
		@media (min-width: $phone-up-bound) and (max-width: #{$tablet-up-bound - 1})
			@content
	@else if $size == tablet-up
		@media (min-width: $tablet-up-bound)
			@content
	@else if $size == tablet-down
		@media (max-width: #{$tablet-up-bound - 1})
			@content
	@else if $size == laptop-only
		@media (min-width: $tablet-up-bound) and (max-width: #{$laptop-up-bound - 1})
			@content
	@else if $size == laptop-up
		@media (min-width: $laptop-up-bound)
			@content
	@else if $size == laptop-down
		@media (max-width: #{$laptop-up-bound - 1})
			@content
	@else if $size == desktop-only
		@media (min-width: $laptop-up-bound) and (max-width: #{$desktop-up-bound - 1})
			@content
	@else if $size == desktop-up
		@media (min-width: $desktop-up-bound)
			@content
	@else if $size == desktop-down
		@media (max-width: #{$desktop-up-bound - 1})
			@content
	@else if $size == wide-desktop-only
		@media (min-width: $desktop-up-bound) and (max-width: #{$wide-desktop-up-bound - 1})
			@content
	@else if $size == wide-desktop-up
		@media (min-width: $wide-desktop-up-bound)
			@content
	@else if $size == wide-desktop-down
		@media (max-width: #{$wide-desktop-up-bound - 1})
			@content