@import "./colors.sass"
@import "ThemeSet/typography.sass"

$borderRadius: 0 
$disabledOpacity: 0.35 

//Header
$headerBorderWidth: 1px 
$headerBorderColor: $clGallery 
$headerBgColor: white 
$headerTextColor: $clTundora 
$headerFontWeight: bold 
$headerIconTextColor: $clTundora 

//Content
$contentBorderWidth: 1px 
$contentBorderColor: #dddddd 
$contentBgColor: #f2f5f7 
$contentTextColor: #362b36 

//Default State
$stateDefaultBorderWidth: 1px 
$stateDefaultBorderColor: $clGallery 
$stateDefaultBgColor: white 
$stateDefaultTextColor: $clTundora 

//Active State
$stateActiveBorderColor: $clDarkSilverChalice 
$stateActiveBgColor: white 
$stateActiveTextColor: $clTundora 

//Highlight State
$stateHighlightBorderColor: $clDarkSilverChalice 
$stateHighlightBgColor: white 
$stateHighlightTextColor: $clTundora 

//Focus State
$stateFocusBorderColor: $clDarkSilverChalice 
$stateFocusBgColor: white 
$stateFocusTextColor: $clTundora 

//Error State
$stateErrorBorderColor: $clCrimson 
$stateErrorBgColor: $clCrimson 
$stateErrorTextColor: #ffffff 

//Hover State
$stateHoverBorderColor: $clDarkSilverChalice 
$stateHoverBgColor: white 
$stateHoverTextColor: $clTundora 

//Forms
$inputBgColor: #ffffff 
$inputTextColor: #222222 
$invalidInputBorderColor: #cd0a0a 
$inputGroupTextColor: #2779aa 