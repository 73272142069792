$tooltipBackground: white
.p-tooltip
	border-radius: 0
	max-width: 16rem
	.p-tooltip-text
		padding: 10px
		box-sizing: border-box
		border-top: 1px solid $clFlushOrange
		background: $tooltipBackground
		color: $clTundora
		font-family: $fontFamily
		font-size: 0.8rem
	.p-tooltip-arrow
		background: $clAlto
.p-tooltip-bottom .p-tooltip-arrow
	border-bottom-color: $tooltipBackground
.p-tooltip-left .p-tooltip-arrow
	border-left-color: $tooltipBackground
.p-tooltip-right .p-tooltip-arrow
	border-right-color: $tooltipBackground
.p-tooltip-top .p-tooltip-arrow
	border-top-color: $tooltipBackground