// white
$clWhite: #fff
$clWhiteSmoke: #f5f5f5

// green
$clLimeade: #5eb700
$clShowFlurry: #e9ffd2
$darkLimeade: #4F9B00
$clVidaLoca: #4e8513

// black
$clTundora: #484848

// red
$clCrimson: #d52222
$clCosmos: #ffd0d0

// grey
$clAlabaster: #f9f9f9
$clLightAlabaster: #fcfcfc
$clSeashell: #f1f1f1
$clWhisper: #eeeeee
$clGreyDim: #C2C9D18A
$clGallery: #efefef
$clNobel: #b3b3b3
$clAlto: #F0F0F0
$clWhitish: #F0F0F4
$clSilverChalice: #aaa
$clLightSilverChalice: #a3a3a3
$clDarkSilverChalice: #a8a8a8
$clSilver: #bdbdbd
$clLightGray: #868686
$clGray: #555
$clLightSilver: #c3c3c3
$clSnow: #c4c4c4
$clLightHeaderGray: #A5A5AA
$cldarkishGray: #485868

// orange
$clFlushOrange: #FF7C00