@import 'ThemeSet/components/dropdown.sass' 

p-dropdown.ng-dirty.ng-invalid > .ui-dropdown
	border: 1px solid $clCrimson
	&:enabled:hover, &:focus
		border: 1px solid $clCrimson

.p-dropdown
	&:hover
		border-color: $clDarkSilverChalice !important
	&-label
		padding: 10px
		width: 100% !important
		color: $clTundora
		&.p-placeholder
			color: #d5d5d5

.p-dropdown-item
	&:hover
		background-color: $clGallery !important

