@import '../colors.sass' 
@import '../breakpoints.sass' 
@import '../variables.sass' 
@import '../typography.sass' 

.header
	height: $headerHeight
	display: flex
	justify-content: space-between
	padding: 0 50px
	align-items: center
	background: $clWhite
	border-bottom: 1px solid $clAlto
	@media (max-width: 1330px)
		padding: 0 50px
	@media (max-width: 1140px)
		height: $smallHeaderHeight
		padding: 0 30px
		position: relative
		&.centered
			justify-content: center
	&-logo
		height: 51px
		@media (max-width: 1280px)
			height: 40px
		+for-size(tablet-down)
			height: 35px
	&-sidebar-toggle
		display: none
		+for-size(tablet-down)
			display: block
			position: absolute
			left: 30px
	&-nav-toggle
		display: none
		@media (max-width: 1120px)
			display: block
			pointer-events: none
			user-select: none
			position: absolute
			right: 30px
			min-height: 26px
			min-width: 26px
			color: $clLimeade
	&-nav-toggle-input
		display: none
		@media (max-width: 1120px)
			display: block
			opacity: 0
			position: absolute
			right: 30px
			height: 30px
			width: 30px
			margin: 0
			&:checked ~ .header-nav
				max-height: calc(100vh - #{$smallHeaderHeight})
				min-width: 100%
			&:checked ~ .header-nav-toggle
				color: $clVidaLoca
	&-nav
		display: flex
		height: 100%
		align-items: center
		max-height: none
		background-color: $clWhite
		@media (max-width: 1120px)
			position: absolute
			top: $smallHeaderHeight
			left: 0
			overflow: hidden
			min-height: 0
			max-height: 0
			max-width: 0
			z-index: 1000
			flex-direction: column
			overflow-y: scroll
			transition: max-height 0.2s
			border-top: 1px solid $clGallery
			border-bottom: 1px solid $clGallery
			height: auto
		&-link
			color: $clTundora
			font-size: 14px
			letter-spacing: .9px
			font-family: $fontFamily
			margin: 0 25px
			text-decoration: none
			&.flex
				display: flex
				align-items: center
			&.fixed
				max-width: 350px
				@media (min-width: 900px) and (max-width: 1240px)
					max-width: 300px
				+for-size(tablet-down)
					max-width: 90%
			&.highlighted
				color: $clFlushOrange
			&:hover
				color: $clLimeade
			&:first-child
				margin-left: 0
			&:last-child
				margin-right: 0
			&.dangerous
				&:hover
					color: $clCrimson
			@media (min-width: 900px) and (max-width: 1240px)
				font-size: 14px
				margin: 0 15px
			+for-size(tablet-down)
				margin: 15px 0
		&-subnav
			height: 100%
			line-height: $headerHeight
			position: relative
			+for-size(tablet-down)
				height: auto
				flex-wrap: wrap
				justify-content: center
				line-height: unset
			&-header
				cursor: pointer
				&.enlarged
					text-transform: uppercase
				&.truncated
					display: inline-block
					flex: 1
					overflow: hidden
					text-overflow: ellipsis
					white-space: nowrap
					+for-size(tablet-down)
						flex: unset
						max-width: 100%
			.carret
				color: $clTundora
				margin-left: 5px
				min-width: 10px
			&-links
				z-index: 1001
				background-color: $clWhite
				left: -25px
				top: $headerHeight
				display: none
				position: absolute
				flex-direction: column
				padding: 20px 0
				width: 225px
				border: 1px solid $clGallery
				& .header-nav-link
					line-height: normal
					margin: 11px 50px
					text-align: center
					@media (min-width: 900px) and (max-width: 1240px)
						margin: 8px 30px
					+for-size(tablet-down)
						margin: 5px 0
				@media (min-width: 900px) and (max-width: 1240px)
					left: -15px
					padding: 10px 0
				+for-size(tablet-down)
					position: unset
					width: 100%
					border: none
					margin: 0
			&:hover
				& > .header-nav-subnav-links
					display: flex
