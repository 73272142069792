.p-datatable
	border: 1px solid $clGallery
	border-top: 1px solid $clFlushOrange
	& &-thead > tr > th
		border: none
		padding: .5rem 0
		border-bottom: 1px solid $clGallery
		color: #484848
	& &-tbody > tr
		&:nth-of-type(2n)
			background-color: $clGallery
		&:nth-of-type(2n - 1)
			background-color: $clWhite
		& > td
			border: none
			padding: .5rem 1rem
			&.centered
				text-align: center
		& .drag-handler
			color: $clSilverChalice
			font-size: .8rem
			margin: .25rem .5rem
			cursor: grab