@import "./theme/colors.sass"
$fontFamily: "Heebo", "Trebuchet MS", Arial, Helvetica, sans-serif

h1, h2, h3, h4, h5, h6, p
	font-family: $fontFamily !important
	color: $cldarkishGray !important

// Bootstrap Overrides

// Form Controls
.form-control
	border: 1px solid $clGreyDim
	border-radius: 8px
	height: 48px
	max-height: 48px
	outline: none
	background-color: $clWhite
	&:focus
		border: 1px solid $clGreyDim !important

// Input Group
.input-group
	border-radius: 8px
	overflow: hidden
	width: 250px !important
	display: flex
	flex-direction: column
	color: $clTundora !important

.input-group-web
	width: 250px !important
	@extend .input-group

.tabbed-content.input-group-web
	width: 1000px !important

.input-group-mobile
	width: 100% !important
	@extend .input-group

.input-group-web-large
	width: 100% !important
	@extend .input-group

.input-group-mobile-large
	width: 100% !important
	@extend .input-group

.g-tabbed-content.input-group-mobile-large
	width: 100% !important
	padding: 18px 0 0 0 !important
	@extend .input-group

.input-group-web-fill
	width: 100% !important
	@extend .input-group

.input-group-autocomplete
	overflow: visible !important
	@extend .input-group-web

.form-control
	box-shadow: unset !important
	border-radius: 8px !important
	background-color: $clWhite
	padding: 0 12px
	margin-left: 0 !important
	font-size: 16px

input::placeholder
	color: #00000040 !important

// Material Overrrides

// Toggle
.double-checked.mat-mdc-slide-toggle.mat-accent
	--mdc-switch-selected-focus-state-layer-color: #5eb700
	--mdc-switch-selected-handle-color: #5eb700
	--mdc-switch-selected-hover-state-layer-color: #5eb700
	--mdc-switch-selected-pressed-state-layer-color: #5eb700
	--mdc-switch-selected-hover-handle-color: #5eb700
	--mdc-switch-selected-focus-handle-color: #5eb700
	--mdc-switch-selected-pressed-handle-color: #5eb700
	--mdc-switch-selected-focus-track-color: #F0F0F0
	--mdc-switch-selected-hover-track-color: #F0F0F0
	--mdc-switch-selected-track-color: #F0F0F0
	--mdc-switch-selected-pressed-track-color: #F0F0F0

.double-checked.mat-mdc-slide-toggle
	--mdc-switch-unselected-focus-state-layer-color: #5eb700
	--mdc-switch-unselected-pressed-state-layer-color: #5eb700
	--mdc-switch-unselected-hover-state-layer-color: #5eb700
	--mdc-switch-unselected-focus-track-color: #F0F0F0
	--mdc-switch-unselected-hover-track-color: #F0F0F0
	--mdc-switch-unselected-pressed-track-color: #F0F0F0
	--mdc-switch-unselected-track-color: #F0F0F0
	--mdc-switch-unselected-focus-handle-color: #5eb700
	--mdc-switch-unselected-hover-handle-color: #5eb700
	--mdc-switch-unselected-pressed-handle-color: #5eb700
	--mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff)
	--mdc-switch-unselected-handle-color: #5eb700
	--mdc-switch-selected-icon-color: #5eb700
	--mdc-switch-disabled-selected-icon-color: #5eb700
	--mdc-switch-disabled-unselected-icon-color: #5eb700
	--mdc-switch-unselected-icon-color: #5eb700

.double-checked.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after
	background: #5EB70033

.standard-toggle.mat-mdc-slide-toggle.mat-accent
	--mdc-switch-selected-focus-state-layer-color: #5eb700
	--mdc-switch-selected-handle-color: #5eb700
	--mdc-switch-selected-hover-state-layer-color: #5eb700
	--mdc-switch-selected-pressed-state-layer-color: #5eb700
	--mdc-switch-selected-hover-handle-color: #5eb700
	--mdc-switch-selected-focus-handle-color: #5eb700
	--mdc-switch-selected-pressed-handle-color: #5eb700
	--mdc-switch-selected-focus-track-color: #F0F0F0
	--mdc-switch-selected-hover-track-color: #F0F0F0
	--mdc-switch-selected-track-color: #F0F0F0
	--mdc-switch-selected-pressed-track-color: #F0F0F0

.standard-toggle.mat-mdc-slide-toggle
	--mdc-switch-unselected-focus-state-layer-color: #A5A5AA
	--mdc-switch-unselected-pressed-state-layer-color: #A5A5AA
	--mdc-switch-unselected-hover-state-layer-color: #A5A5AA
	--mdc-switch-unselected-focus-track-color: #F0F0F0
	--mdc-switch-unselected-hover-track-color: #F0F0F0
	--mdc-switch-unselected-pressed-track-color: #F0F0F0
	--mdc-switch-unselected-track-color: #F0F0F0
	--mdc-switch-unselected-focus-handle-color: #A5A5AA
	--mdc-switch-unselected-hover-handle-color: #A5A5AA
	--mdc-switch-unselected-pressed-handle-color: #A5A5AA
	--mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff)
	--mdc-switch-unselected-handle-color: #A5A5AA
	--mdc-switch-selected-icon-color: #5eb700
	--mdc-switch-disabled-selected-icon-color: #A5A5AA
	--mdc-switch-disabled-unselected-icon-color: #A5A5AA
	--mdc-switch-unselected-icon-color: #A5A5AA

.standard-toggle.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after
	background: #5EB70033

// Checkbox
.mat-mdc-checkbox-checked.mat-accent
	.mat-mdc-checkbox-background
		background-color: $clLimeade

.unique-checkbox.mat-mdc-checkbox-checked.mat-accent
	--mdc-checkbox-disabled-selected-icon-color: #5eb700

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected~.mdc-checkbox__ripple
	background-color: $clLimeade !important

.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background
	background-color: $clLimeade !important
	border-color: $clLimeade !important

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background
	border-color: $clLimeade !important
	background-color: $clLimeade !important

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark
	color: white !important

.mat-mdc-checkbox label
	font-size: 16px !important
	color: #485868 !important

// Tabs
.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label
	background-color: transparent !important
	color: $clFlushOrange !important
	font-weight: 700

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline
	border-color: $clFlushOrange !important

.mat-mdc-tab .mdc-tab__text-label
	color: #CBD5E1

.mat-mdc-tab.mdc-tab
	width: 50%

.ticket-resale-select-table .mat-mdc-tab-body-content
	height: auto !important

.ticket-resale-select-table .mat-mdc-tab-group
	padding-bottom: 200px


// Nav Tabs
.mat-mdc-tab-link:not(.mat-mdc-tab-link-disabled).mdc-tab--active .mdc-tab__text-label
	background-color: transparent !important
	color: $clFlushOrange !important
	font-weight: 700

.mat-mdc-tab-link:not(.mat-mdc-tab-link-disabled) .mdc-tab-indicator__content--underline
	border-color: $clFlushOrange !important

.mat-mdc-tab-link .mdc-tab__text-label
	color: #CBD5E1

// Select
.mat-mdc-select
	display: flex !important
	align-items: center !important
	font-family: "Heebo", "Trebuchet MS", Arial, Helvetica, sans-serif !important
	font-weight: 200

.mat-mdc-select-placeholder
	color: #94A3B8
	font-weight: 200

.mdc-menu-surface.mat-mdc-select-panel
	margin-left: -13px !important

.schedule-select .mat-mdc-select-text
	padding: 20px
	color: #A5A5AA
	font-family: Heebo

.schedule-select .mat-mdc-form-field-icon-prefix
	position: absolute
	z-index: 1
	padding-left: 8px !important
	padding-left: 8px !important

.schedule-select .mat-mdc-select-placeholder
	padding-left: 20px

.schedule-select .mdc-text-field
	padding: 0px !important

.schedule-select .mat-mdc-select-value-text
	padding-left: 24px
	color: #A5A5AA
	font-family: Heebo

// Select Countries
.mat-mdc-form-field-flex
	height: 48px !important
	align-items: center !important

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input
	color: #485868 !important
	font-size: 16px !important
	padding-left: 0px !important

.mat-mdc-form-field
	width: 100% !important

.mdc-line-ripple
	display: none !important

.mdc-text-field--outlined .mdc-notched-outline
	opacity: 0 !important

.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control
	height: auto !important

.mat-mdc-text-field-wrapper
	border-radius: 8px !important

.mdc-text-field--filled:not(.mdc-text-field--disabled)
	background-color: #E7E7EE !important

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label
	color: transparent !important
	margin-top: 5px !important

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label
	margin-top: 6px !important
	font-family: "Heebo", "Trebuchet MS", Arial, Helvetica, sans-serif !important
	font-size: 16px !important
	color: #94A3B8 !important
	font-weight: 200 !important

.mdc-floating-label--float-above
	display: none !important

.mat-mdc-form-field-focus-overlay
	background-color: #a8a8a8 !important

// Date Picker
.mat-calendar-body-selected
	color: #fff !important

.mat-date-range-input-container
	margin-bottom: 16px !important

.mat-calendar-previous-button:after, .mat-calendar-next-button:after
	margin: 20px !important

.mat-datepicker-actions
	gap: 8px

// Bottom Sheet
.mat-bottom-sheet-container
	border-radius: 8px !important

// Datetime picker
.ngx-mat-timepicker form .ngx-mat-timepicker-table .ngx-mat-timepicker-tbody tr td.ngx-mat-timepicker-spacer
	display: flex
	padding-top: 12px

// Badge
.mat-badge-content
	color: #fff !important

// Radio Button
.mat-mdc-radio-button.mat-accent
	--mdc-radio-selected-focus-icon-color: #5eb700
	--mdc-radio-selected-hover-icon-color: #5eb700
	--mdc-radio-selected-icon-color: #5eb700
	--mdc-radio-selected-pressed-icon-color: #5eb700
	--mat-mdc-radio-checked-ripple-color: #5eb700

.mat-mdc-radio-button .mdc-form-field
	color: #485868

// Expansion Panel
.mat-accordion
	display: flex
	flex-direction: column
	justify-content: center

.mat-expansion-panel-header
	height: 80px

.mat-accordion .mat-expansion-panel
	border-radius: 8px !important

.mat-expansion-panel
	box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05) !important

.mat-expansion-panel-header.mat-expanded
	height: 80px

.mat-expansion-panel-body
	margin-top: 16px

// Slider
.mat-slider-vertical.mat-slider-track-wrapper.mat-slider-track-background,.mat-slider-track-fill
	width: 4px

// Menu Expansion
.menu.mat-expansion-panel
	box-shadow: none !important

.menu.mat-expansion-panel-header
	height: 32px
	padding: 0 24px 0 8px !important
	color: #485868

.menu.mat-expansion-panel-header.mat-expanded
	height: 48px
	background-color: $clLimeade !important
	.mat-expansion-panel-header-title
		color: #fff !important
	.mat-expansion-indicator::after
		color: #fff !important

// Table
.mdc-data-table__cell
	font-size: 14px !important
	font-weight: 400 !important
	color: #485868

.mdc-data-table__header-cell
	color: #485868 !important
	text-align: center

.mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow
	opacity: 0.4 !important
	transform: translateY(0px) !important

.mat-sort-header-arrow
	color: $clLimeade !important

// Table Web
.web.mat-mdc-table
	border-collapse: separate !important
	border-spacing: 0 8px !important
	background: #FCFCFC !important

.web.mat-mdc-table .mdc-data-table__header-row
	background: #F0F0F4
	border-radius: 8px
	outline: 1px solid #F0F0F4

.web.mat-mdc-table .mat-mdc-header-cell
	border: none !important
	&:first-child
		border-radius: 0 0 0 8px
	&:last-child
		border-radius: 0 0 8px 0

.web.mat-mdc-table .mdc-data-table__row
	background: #FFF
	outline: 1px solid #F1F5F9
	border-radius: 8px
	box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05)

.web.mat-mdc-table .mat-mdc-cell.mdc-data-table__cell
	border-bottom: none
	&:first-child
		border-radius: 8px 0 0 8px
		border-left: 1px solid #F0F0F4
	&:last-child
		border-radius: 0 8px 8px 0
		border-right: 1px solid #F0F0F4

// Table Expansion
.expansion.mat-mdc-table
	border-collapse: separate !important

.expansion.mat-mdc-table .mdc-data-table__header-row
	background: #F0F0F4
	border-radius: 8px
	height: 76px !important

.expansion.mat-mdc-table .mat-mdc-header-cell
	border-bottom: 12px solid #f8f8f8
	border-top: 1px solid #F0F0F4
	&:first-child
		border-radius: 8px 0 0 0
		border-left: 1px solid #F0F0F4
	&:last-child
		border-radius: 0 8px 0 0
		border-right: 1px solid #F0F0F4

.expansion.mat-mdc-table .mdc-data-table__row
	height: 76px !important
	background: #FFF !important
	border-radius: 8px

.expansion.mat-mdc-table .mdc-data-table__row .expansion-parent
	border-bottom: 12px solid #f8f8f8
	border-top: 1px solid #F0F0F4
	&:first-child
		border-radius: 8px 0 0 0
		border-left: 1px solid #F0F0F4
	&:last-child
		border-radius: 0 8px 0 0
		border-right: 1px solid #F0F0F4

.expansion.mat-mdc-table .current-expansion-parent
	border-top: 1px solid #F0F0F4
	border-bottom: none
	&:first-child
		border-radius: 8px 0 0 0
		border-left: 1px solid #F0F0F4
	&:last-child
		border-radius: 0 8px 0 0
		border-right: 1px solid #F0F0F4

// Table Mobile
.mobile.mat-mdc-table
	background: #FCFCFC !important

.mobile.mat-mdc-table .mdc-data-table__header-row
	background: #F0F0F4
	border-radius: 8px
	outline: 1px solid #F0F0F4

.mobile.mat-mdc-table .mat-mdc-header-cell
	border: none !important

.mobile.mat-mdc-table .mdc-data-table__row:nth-child(odd)
	background: #FFF
	outline: 1px solid #F1F5F9
	border-radius: 8px

.mobile.mat-mdc-table .mdc-data-table__row:nth-child(even)
	background: #F0F0F4
	outline: 1px solid #F0F0F4
	border-radius: 8px

// Menu
.mdc-menu-surface
	border-radius: 8px !important
	box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15) !important

.mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text
	font-size: 14px !important
	font-weight: 400 !important
	color: #485868 !important
	font-family: 'Heebo' !important

// Mat Autocomplete
.mdc-menu-surface.mat-mdc-autocomplete-panel
	max-height: 400px !important

.mat-mdc-optgroup-label .mdc-list-item__primary-text
	font-size: 20px
	font-weight: 600
	color: #485868

// Overlay
.cdk-overlay-dark-backdrop
	background: #485868bf

.cdk-overlay-pane .g-full-page-dialog, .mat-mdc-dialog-container .mdc-dialog .cdk-dialog-container .mdc-dialog--open, .mdc-dialog__container, .mat-mdc-dialog-surface.mdc-dialog__surface
	@media screen and (max-width: 740px)
		border-radius: 0px !important

.ngx-mat-tel-input-mat-menu-panel
	height: 300px

// Paginator
.mat-mdc-paginator-page-size-select
	width: 84px !important

.mat-mdc-paginator-page-size-label
	width: 90px

// Stepper
.mat-stepper-vertical, .mat-stepper-horizontal
	font-family: 'Heebo' !important

.mat-horizontal-stepper-header
	gap: 6px

.mat-vertical-stepper-header
	height: 52px !important

.mat-vertical-stepper-header .mat-step-icon
	margin-left: 12px !important

.mat-vertical-content-container
	margin-left: 0px !important

.mat-step-header
	border-radius: 8px

.mat-step-header:hover:not([aria-disabled])
	background: none

.mat-step-header.cdk-program-focused
	background: none

.mat-step-icon
	width: 30px !important
	height: 30px !important

.mat-step-header .mat-step-icon-selected
	color: #fff !important
	background-color: $clLimeade !important
	outline: 5px solid #D2E6BE

.mat-step-label-selected
	color: $clLimeade !important
	font-weight: 500
	font-size: 16px

.mat-step-header .mat-step-label.mat-step-label-active
	color: $clLightHeaderGray

.mat-stepper-horizontal-line
	border-top-width: 2px !important

.mat-step-header .mat-step-icon
	background-color: #E7E7EE
	font-weight: 600

// Algolia
.ais-Highlight-highlighted, .ais-Snippet-highlighted
	background-color: #5eb7000d !important
	color: $clLimeade !important

.ais-ClearRefinements-button
	background: none !important
	border: none !important
	color: $clLimeade
	font-family: "Heebo", "Trebuchet MS", Arial, Helvetica, sans-serif !important
	box-shadow: none !important

.ais-ClearRefinements-button:disabled
	color: $cldarkishGray !important

.ais-RefinementList-count
	background: none !important
	border: none !important
	color: $cldarkishGray !important
	font-family: "Heebo", "Trebuchet MS", Arial, Helvetica, sans-serif !important

.ais-RefinementList-checkbox
	border: 0.65px solid #48586880
	box-shadow: inset 0 0px 2px #777aaf66 !important

.ais-RefinementList-item--selected .ais-RefinementList-checkbox, .ais-GeoSearch-input:checked
	color: $clLimeade !important
	border: none !important
	background-color: $clLimeade
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>')

.ais-RefinementList-item
	margin: 12px 0

.ais-RefinementList-showMore
	background: none !important
	border: none !important
	color: $cldarkishGray !important
	box-shadow: none !important

.ais-SearchBox
	width: 100% !important
	margin: 0 !important

.ais-SearchBox .ais-SearchBox-form .ais-SearchBox-input
	display: flex
	padding-left: 46px !important
	border: none !important
	height: 44px !important
	width: 100% !important
	border-radius: 8px !important
	box-shadow: #777aaf4d 0 1px 4px !important

.ais-SearchBox-form
	&::before
		background: transparent !important
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='18' height='18' viewBox='0 0 1080 1080'%3E%3Cg transform='matrix(1 0 0 1 540 540)'%3E%3Crect style='stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1; visibility: hidden;' vector-effect='non-scaling-stroke' x='-540' y='-540' rx='0' ry='0' width='1080' height='1080' /%3E%3C/g%3E%3Cg transform='matrix(1 0 0 1 540 540)'%3E%3C/g%3E%3Cg transform='matrix(30 0 0 30 510 510)' %3E%3Ccircle style='stroke: rgb(94,183,0); stroke-width: 2; stroke-dasharray: none; stroke-linecap: round; stroke-dashoffset: 0; stroke-linejoin: round; stroke-miterlimit: 4; fill: none; fill-rule: nonzero; opacity: 1;' vector-effect='non-scaling-stroke' cx='0' cy='0' r='8' /%3E%3C/g%3E%3Cg transform='matrix(30 0 0 30 744.75 744.75)' %3E%3Cline style='stroke: rgb(94,183,0); stroke-width: 2; stroke-dasharray: none; stroke-linecap: round; stroke-dashoffset: 0; stroke-linejoin: round; stroke-miterlimit: 4; fill: none; fill-rule: nonzero; opacity: 1;' vector-effect='non-scaling-stroke' x1='2.1750000000000007' y1='2.1750000000000007' x2='-2.1750000000000007' y2='-2.1750000000000007' /%3E%3C/g%3E%3C/svg%3E") !important

.ais-Pagination-item+.ais-Pagination-item>.ais-Pagination-link
	height: 40px
	border-radius: 8px
	margin: 2px

.ais-Pagination-item--selected .ais-Pagination-link
	border-color: #5eb70059
	box-shadow: #5eb70094 0 1px 4px inset, #5eb70042 0 1px 1px inset, #5eb7004a 0 1px

.ais-Pagination-item:first-child>.ais-Pagination-link
	border-radius: 8px

.ais-Pagination-item:last-child>.ais-Pagination-link
	border-radius: 8px

.ais-Pagination-list
	justify-content: center

//
.ngx-ic-overlay
	border-radius: 8px

.textarea:focus-visible
	outline: none !important

//WYSIWYG Text Editor
.ui-editor-container .ui-editor-toolbar
	border-top-left-radius: 8px
	border-top-right-radius: 8px
	background-color: #F0F0F4 !important

.ui-editor-container .ql-toolbar.ql-snow + .ql-container.ql-snow
	border-bottom-left-radius: 8px
	border-bottom-right-radius: 8px

.ql-action
	color: $clLimeade !important

.ql-picker-label, .ql-picker-item
	color: #485868 !important

.ql-stroke
	stroke: #485868 !important

.ql-fill
	fill: #485868 !important

//Autocomplete
.ui-inputtext, .p-multiselect .ui-multiselect-label
	border-radius: 8px
	height: 48px !important
	max-height: 48px !important
	border: 1px solid #ced4da !important

	&:enabled
		&:hover
			border-color: #ced4da

.ui-autocomplete
	margin-bottom: 0 !important

.map-container
	border-radius: 8px

//Google Place Autocomplete
.pac-matched
	font-family: "Heebo", "Trebuchet MS", Arial, Helvetica, sans-serif !important
	color: $clLimeade !important

.pac-icon
	display: none !important

.pac-item
	border: none !important

.pac-container
	border-radius: 8px !important
	box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15) !important
	z-index: 99999 !important

.pac-container.single-element
	left: 0 !important
	top: 0 !important
	position: relative !important

.mat-select-search-input
	background-color: white !important

input:-webkit-autofill
	transition: background-color 5000s ease-in-out 0s
	background-color: white !important

input:-webkit-autofill:hover
	background-color: white !important

input:-webkit-autofill:focus
	background-color: white !important

input:-webkit-autofill:active
	background-color: white !important

input:-moz-ui-autofill
	background-color: white !important


//tele-input
.ngx-mat-tel-input-container
	height: 100%
	display: flex

.country-selector
	opacity: 1 !important

.l-nav-header-menu .mat-mdc-menu-content
	padding: 0 !important

.l-nav-header-menu
	border-radius: 0 0 8px 8px !important