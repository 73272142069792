h1
	font-family: $fontFamily
	font-weight: bold
	font-size: 22px
	color: $clNobel
	@media screen and (max-width: 640px)
		font-size: 20px
h2
	font-family: $fontFamily
	font-weight: 500
	color: $clTundora
	font-size: 16px