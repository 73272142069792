$defaultItem: #333
$defaultText: #dedede
$activeItem: #2e2e2e
$activeText: white
$borderColor: #59ad00

.ui-panelmenu
	width: 100%
	height: 100%
	overflow-y: auto
	padding: 0
	background: $defaultItem
	border: 0
	-ms-overflow-style: none
	&::-webkit-scrollbar
		width: 0
	& &-panel
		.ui-state-default
			background: $defaultItem
	& &-header
		height: 48px
		margin: 0
		border: 0
		border-left: 4px solid transparent
		&-link
			color: $defaultText
			padding: 14px 20px 14px
			position: relative
			font-size: 14px
			[class*="angle"]
				position: absolute
				right: 20px
				top: 37% 
		&:not(.ui-state-active):hover
			background: $activeItem
			border-left: 4px solid $activeItem
			a
				color: $activeText
		
	& &-content
		margin: 0
		padding: 0
		border: 0
		.ui-menuitem
			background-color: $activeItem
			&-link
				height: 34px
				color: $defaultText
				padding: 7px 10px 7px 48px
				font-size: 14px
				&:hover
					color: $activeText
					background: $activeItem
		&-wrapper
			border-left: 4px solid transparent 
		& .ui-submenu-list
			.ui-menuitem:last-child
				padding-bottom: 10px
	& &-icon
		font-size: 0.8rem
	.ui-state-active
		border: 0
		border-left: 4px solid $borderColor
		background: $activeItem !important
		a
			color: $activeText !important
		& + .ui-panelmenu-content-wrapper
			border-left: 4px solid $borderColor
		[class*="angle"]
			position: absolute
			right: 20px
			top: 37%
	& .ui-menuitem
		margin: 0
		&-icon
			vertical-align: baseline
	.ui-submenu-list
		background-color: #333
.ui-menubar
	background: transparent
	color: #f9f9f9
	border: 0
	height: 87px
	display: flex
	align-items: center
	.ui-menubar-root-list
		&>.ui-menuitem
			&>.ui-menuitem-link
				color: $clTundora
				background: transparent
				font-size: 16px
				text-transform: uppercase
				&:hover
					background: transparent
					color: $clTundora
	.ui-submenu-list
		top: 86px !important
		box-shadow: none
		border: 1px solid $clAlto
		border-top: 0
		padding: 0
		background: white
		@media screen and (max-width: 640px)
			top: 73px !important
			right: 0 !important
			left: unset !important
			z-index: 100001 !important
		.ui-menuitem
			text-transform: uppercase
			padding: 0
			margin: 0
			&-link
				padding: 20px
		.ui-state-active
			display: none