.ui-autocomplete
	width: 100%
	margin-bottom: 1rem
	zoom: 1
	cursor: pointer
	box-shadow: none
	position: relative
	display: inline-block
.ui-inputtext
	margin-bottom: 0
	width: 100%
.ui-autocomplete-panel
	@include ui-dropdown-panel
	margin: 0
	position: absolute
	overflow: auto
.ui-autocomplete-items	
	@include ui-dropdown-items
	list-style: none
	margin: 0
.ui-autocomplete-list-item
	@include ui-dropdown-item
.ui-autocomplete-panel .ui-autocomplete-list-item
	margin: 1px 0
.ui-helper-reset
	line-height: 1.3