a.ui-button-medium
	line-height: 56px
.ui-button
	font-family: $fontFamily
	border-radius: 50px
	box-sizing: border-box
	border: 0
	background-color: $clWhite
	text-transform: uppercase
	font-weight: bold
	width: 287px
	margin: 0 .2rem
.ui-button.ui-button
	&-primary
		background-color: $clLimeade
		color: $clWhite
		&:focus
			background-color: $clLimeade
			color: $clWhite
			box-shadow: none
		&:enabled:hover
			background-color: $darkLimeade
			color: $clWhite
			box-shadow: none
		&:disabled
			background-color: $clAlto
			border: 0
			color: $clWhite
	&-secondary
		background-color: $clWhite
		color: $clLightGray
		border: 1px solid $clLightGray
		&:focus
			background-color: $clWhite
			color: $clLightGray
			border: 1px solid $clLightGray
		&:enabled:hover
			background-color: $clWhite
			border: 1px solid $clGray
			color: $clGray
		&:disabled
			background-color: $clWhite
			border: 1px solid $clAlto
			color: $clAlto
	&-warning
		background-color: $clFlushOrange
		color: $clWhite
		&:focus
			background-color: darken($clFlushOrange, 5)
			color: $clWhite
		&:enabled:hover
			background-color: darken($clFlushOrange, 5)
			color: $clWhite
		&:disabled
			background-color: $clAlto
			border: 0
			color: $clWhite
	&-small
		height: 40px
		width: 240px
		font-size: 0.8rem
		[class*='ui-button-icon']
			margin-top: -.4em
		&.ui-button-icon-only
			width: 40px
	&-medium
		height: 55px
	&-large
		height: 71px
	&-icon-right
		right: 1.5rem
	&-icon-left
		left: 1.5rem
	&-simple
		height: auto
		width: auto
		color: $clLimeade
		background: transparent
		text-transform: none
		font-weight: normal
		margin: 0 .1rem
		font-size: 0.9rem
		@media screen and (max-width: 640px)
			text-align: left
		&:enabled:hover, &:focus
			color: $darkLimeade
			background: transparent
		&.danger
			color: $clCrimson
			&:enabled:hover
				color: darken($clCrimson, 10)
		.ui-button-text
			padding: 0
		&.ui-button-text-icon-left
			.ui-button-icon-left
				left: 0
				line-height: .75rem
			.ui-button-text
				padding-left: 1.5rem
		&:disabled
			background-color: transparent
			color: $clDarkSilverChalice
	&-tiny
		height: 30px
		font-size: 0.8rem
		padding: 0.25rem 1rem
		width: auto
		& .ui-button-text
			padding: 0
		[class*='ui-button-icon']
			margin-top: -.4em
		&.ui-button-icon-only
			padding: 0.25em
			width: 30px
			& .fa
				top: 47%
			& .ui-button-text
				width: 20px
				height: 20px
		&.ui-button-text-icon-left
			padding-left: 1.8rem
			.ui-button-icon-left
				left: .5rem
				margin-top: -6px
				line-height: .7rem
	&-link
		display: inline-flex
		align-items: center
		justify-content: center
.ui-button-group
	.ui-button
		margin: 0
		border-radius: 0
		border: 1px solid $clLightGray
		border-right: 0
		padding: .25rem .75rem
		@media screen and (max-width: 900px)
			padding: 0.25rem 0.5rem
		@media screen and (max-width: 640px)
			padding: 0.25rem
		&:hover, &:focus, &:active
			border: 1px solid $clGray
			border-right: 0
		&:first-child
			border-top-left-radius: 50px
			border-bottom-left-radius: 50px
		&:last-child
			border-top-right-radius: 50px
			border-bottom-right-radius: 50px
			border-right: 1px solid $clLightGray
			&:hover, &:focus, &:active
				border-right: 1px solid $clGray

